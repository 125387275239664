import { MESSAGE_TYPE } from "./constants";

class MonnifyPopupService {
  static isWebInitialized = false;
  static postMessage(type, message) {
    MonnifyPopupService.isWebInitialized = true;
    let messageToPost = {
      type: type,
      message: message,
    };

    let stringMsg = JSON.stringify(messageToPost);
    //please do not delete this asterilk , it is to send to all targets
    window.parent.postMessage(stringMsg, "*");
  }

  static postTransactionCompletionCommand(message) {
    this.postMessage(MESSAGE_TYPE.COMPLETE, message);
  }

  static postPopupClosingCommand(message) {
    this.postMessage(MESSAGE_TYPE.CLOSE, message);
  }
}

export default MonnifyPopupService;
