export const MESSAGE_TYPE = {
  CLOSE: "MonnifyPopupClose",
  COMPLETE: "MonnifyPopupComplete",
  LOADED: "MonnifyPopupLoaded",
  CANCEL: "MonnifyPopupCancel",
  INIT_POPUP: "INIT_POPUP",
  TRANSACTION_SUCCESS: "TRANSACTION_SUCCESS",
  TRANSACTION_FAILED: "TRANSACTION_FAILED",
};

export const ERROR_BANNER = "ERROR_BANNER";
export const SUCCESS_BANNER = "SUCCESS_BANNER";

export const PAYMENT_METHODS = {
  ACCOUNT_TRANSFER: "ACCOUNT_TRANSFER",
  CARD: "CARD",
  USSD: "USSD",
  DIRECT_DEBIT: "DIRECT_DEBIT",
  PHONE_NUMBER: "PHONE_NUMBER",
  NQR: "NQR",
  CASH: "CASH",
};

export const PAY_WITH_OTHER_METHODS = "Pay with other methods";

export const POLLING_DELAY_INTERVAL = 5000;

export const POLLING_START_DELAY = 10000;
