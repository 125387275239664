

export const removeSpaces = (input) => {
    return input?.replace(/\s/g, '')
}

export const formatAsCardNumber = (input) => {
  return input?.match(/(.{1,4})/g)?.join(" ")
}

export const formastAsExpiryCardNumber = (input) => {
    return input?.match(/(.{1,2})/g)?.join("/")
}

export const removeSlashCharacter = (input) => {
    return input?.replace(/\//g, '')
}