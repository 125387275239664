import React, { useContext } from "react";
import MonnifyIcons from "../../icon";
import { PADLOCK_ICON } from "../../icon/icon";
import { MESSAGE_TYPE } from "../../services/constants";
import StoreContext from "../../services/store/store-context";
import "./index.scss";
import Fade from "react-reveal";

const Error = () => {
  const context = useContext(StoreContext);
  const onClose = () => {
    context.postMessage({ type: MESSAGE_TYPE.CANCEL, data: {} }, "*");
    console.log("called to close popup");
  };

  return (
    <Fade bottom>
      <div className="status-paymentContainer-wrapper error-wrapper">
        <div className="failed-wrapper">
          <div className="icon">
            <MonnifyIcons type="FAILURE_ICON" />
          </div>
          <h2 className="title text-center">Transaction Failed!</h2>

          <article className="article pt-2">
            Unable to process your transaction request.
          </article>
        </div>

        <div className="top-menu">
          <div onClick={onClose}>
            <h2>CLOSE X</h2>
          </div>
        </div>
        <div className="bottom-menu">
          <div style={{ paddingBottom: 8 }}>
            <MonnifyIcons type={PADLOCK_ICON} />
          </div>

          <div style={{ display: "flex", paddingBottom: 3 }}>
            <h2 style={{ paddingLeft: 3, paddingRight: 6 }}>
              Secured by <span className="monnify-text">monnify</span>
            </h2>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Error;
