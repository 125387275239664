export const ROLLER_ICON = "ROLLER_ICON";
export const SPINNER_WHITE = "SPINNER_WHITE";
export const ASK_ICON = "ASK_ICON";
export const ERROR_INFO = "ERROR_INFO";
export const COPY_ICON = "COPY_ICON";
export const CANCEL_ICON = "CANCEL_ICON";
export const TRANSFER_ICON = "TRANSFER_ICON";
export const ARROW_FORWARD_ICON = "ARROW_FORWARD_ICON";
export const CASH_ICON = "CASH_ICON";
export const BANK_ICON = "BANK_ICON";
export const USSD_ICON = "USSD_ICON";
export const NQR_ICON = "NQR_ICON";
export const CARD_ICON = "CARD_ICON";
export const PROGRES_BAR = "PROGRES_BAR";
export const OTP_IMAGE = "OTP_IMAGE";
export const PHONE_ICON = "PHONE_ICON";
export const PAY_ATTITUDE_ICON = "PAY_ATTITUDE_ICON";
export const START_ALL_OVER_ICON = "START_ALL_OVER_ICON";
export const GREEN_CHECK_ICON = "GREEN_CHECK_ICON";
export const OTP_ICON = "OTP_ICON";
export const PADLOCK_ICON = "PADLOCK_ICON";
export const WARNING_ICON = 'WARNING_ICON'