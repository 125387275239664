import { useCallback, useContext, useEffect, useRef } from "react";
import Loader from "../../../component/loader";
import MonnifyIcons from "../../../icon";
import { POLLING_DELAY_INTERVAL } from "../../../services/constants";
import StoreContext from "../../../services/store/store-context";
import {
  isTransactionCompleted,
  isTransactionExpired,
  isTransactionFailed,
  isTransactionSuccessful,
  TRANSACTION_STATUS,
} from "../../../services/transaction-status.constant";
import TransactionService from "../../../services/transaction.service";
import Loading from "../../bank/Loading";
import "./index.scss";

let IntervalID = null;

const MastercardAuthorization = ({ onComplete }) => {
  const context = useContext(StoreContext);
  const cardDetails = context.getCardDetails();
  const divRef = useRef();

  const startPolling = useCallback(() => {
    IntervalID = setInterval(() => {
      TransactionService.getCardTransactionStatus(
        context?.paymentInfo?.configData?.apiUrl,
        context?.paymentInfo?.paymentData?.transactionReference
      )
        .then((response) => {
          let responseData = (response && response.data) || {};
          if (
            !responseData ||
            !responseData.requestSuccessful ||
            !responseData.responseBody
          ) {
            //todo: handle error confirmation;
            //   onFailure && onFailure();
            //   this.onFlowComplete(TRANSACTION_STATUS.PENDING, {});
            return onComplete(TRANSACTION_STATUS.PENDING, {});
          }

          let responseBody = responseData.responseBody;
          let transactionStatus =
            responseBody.chargeStatus || TRANSACTION_STATUS.EXPIRED;
          onComplete(transactionStatus, responseBody);
        })
        .catch((error) => {
          let errorData =
            (error && error.response && error.response.data) || {};
          // let transactionStatus = errorData && errorData.paymentStatus;

          // onComplete(TRANSACTION_STATUS.FAILED, errorData);
        });
    }, POLLING_DELAY_INTERVAL);
  }, []);

  useEffect(() => {
    startPolling();
    return () => {
      if (IntervalID) {
        clearInterval(IntervalID);
      }
      TransactionService.cancelAllRequest();
    };
  }, [startPolling]);

  useEffect(() => {}, []);

  useEffect(() => {
    const fragment = document
      .createRange()
      .createContextualFragment(cardDetails.message);
    divRef.current.append(fragment);
  }, [cardDetails.message]);

  if (cardDetails.message)
    return (
      <div className="col-md-12 no-horizontal-padding mastercard-wrapper">
        <div
          ref={divRef}
          id="wrapper_master_card"
          dangerouslySetInnerHTML={{ __html: cardDetails.message }}
        ></div>
        <div className="master-card-roller">
          <Loader text={"Awaiting authorization"} />
        </div>
      </div>
    );

  return (
    <div className="col-md-12 no-horizontal-padding mastercard-wrapper">
      <div className="loading-container">
        <MonnifyIcons type="ROLLER_ICON" style={{}} />
      </div>
    </div>
  );
};

export default MastercardAuthorization;
