import React, { useCallback, useContext, useEffect, useState } from "react";

import { PAYMENT_METHODS } from "../../services/constants";
import StoreContext from "../../services/store/store-context";
import TransactionService from "../../services/transaction.service";
import { useNavigate } from "react-router-dom";
import { STAGES as APP_STAGE } from "../../App";
import "./index.scss";
import constants from "../../constants";
import { isTestEnvironment } from "../../services/config.service";

const AppLoading = ({ paymentData, canAppLoad }) => {
  const naviagtion = useNavigate();
  const context = useContext(StoreContext);
  const [isLoaded, setIsLoaded] = useState(true);

  const getRef = useCallback(async () => {
    try {
      context.updatePayloadFromHtmlInit(paymentData);

      const response = await TransactionService.initializeTransaction(
        paymentData
      );

      if (!response?.data?.data) return naviagtion(`/e`);

      context.updatePaymentData(response.data.data);
      setDefaultActiveMenut(response.data.data);
      setEnvironment(response.data.data);
      naviagtion(
        `/checkout/${response.data.data.paymentData.transactionReference}`
      );
    } catch (error) {
      console.log("error: ", error);

      context.setPaymentCompleteInfo({
        status: "LOADING_FAILED",
        ...error?.response?.data,
        configData: null,
      });

      context.changeTransactionStage(
        APP_STAGE.LOADING_FAILED,
        error?.response?.data?.errorMessage
      );
      naviagtion(`/e`);
    }
  }, [paymentData, naviagtion]);
  const setEnvironment = (payload) => {
    if (isTestEnvironment(payload?.paymentData)) {
      return context.updateTestMode(true);
    }
    return context.updateTestMode(false);
  };
  const setDefaultActiveMenut = (data) => {
    const enablePaymentMethods = data?.paymentData?.enabledPaymentMethods || [];

    if (enablePaymentMethods.includes(PAYMENT_METHODS.ACCOUNT_TRANSFER)) {
      return context.updateActiveLink(constants.PAY_WITH_ACCOUNT_TRANSFER);
    } else if (enablePaymentMethods.includes(PAYMENT_METHODS.CARD)) {
      return context.updateActiveLink(constants.PAY_WITH_CARD);
    } else if (enablePaymentMethods.includes(PAYMENT_METHODS.USSD)) {
      return context.updateActiveLink(constants.PAY_WITH_USSD);
    } else if (enablePaymentMethods.includes(PAYMENT_METHODS.PHONE_NUMBER)) {
      return context.updateActiveLink(constants.PAY_WITH_PHONE_NO);
    } else if (enablePaymentMethods.includes(PAYMENT_METHODS.DIRECT_DEBIT)) {
      return context.updateActiveLink(constants.PAY_WITH_BANK);
    }
  };
  useEffect(() => {
    if (canAppLoad && isLoaded) {
      if (paymentData) {
        getRef();
        return setIsLoaded(false);
      }
    }
  }, [getRef, canAppLoad, isLoaded, paymentData]);

  const [timeIntervalID, setIntervalId] = useState(null);

  useEffect(() => {
    if (timeIntervalID) {
      clearTimeout(timeIntervalID);
    }
    const intervalId = setTimeout(() => {
      if (!context.paymentInfo) {
        return naviagtion("/e");
      }
    }, 40000);
    setIntervalId(intervalId);
  }, [context]);

  return (
    <div className="status-paymentContainer-wrapper-2 init-loading-wrapper">
      <div className="h-100 w-100 d-flex justify-content-center align-items-center">
        <div className="MonnifyPreLoader" id="MonnifyPreLoader">
          <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLoading;
