import React, { useContext, useMemo, useState } from "react";
import { STAGES } from "../../App";
import constants from "../../constants";
import {
  BANK_ICON,
  CARD_ICON,
  USSD_ICON,
  TRANSFER_ICON,
  PHONE_ICON,
} from "../../icon/icon";
import { PAYMENT_METHODS } from "../../services/constants";
import StoreContext from "../../services/store/store-context";
import "./index.scss";
import { ListItem } from "./ListIem";

const Menu = ({ onActiveLinkChange }) => {
  const context = useContext(StoreContext);

  const [, setActiveLink] = useState(context.activeLink);
  const onListItemPress = (listItem) => {
    if (context.stage !== STAGES.TRANSACTION_PROCESSING) {
      if (context.fallBackOptions && context.fallBackOptions.length > 0)
        context.changeTransactionStage(STAGES.TRANSACTION_PROCESSING);
    }

    setActiveLink(listItem);
    onActiveLinkChange(listItem);
  };
  const getClass = (listItem) => {
    return context.activeLink === listItem ? "active " : " ";
  };

  const enabledPaymentMethods = useMemo(
    () => context?.paymentInfo?.paymentData?.enabledPaymentMethods || [],
    [context?.paymentInfo?.paymentData]
  );
  return (
    <div className="wrapper">
      <ul className="desktop-menu-wrapper">
        {enabledPaymentMethods.includes(PAYMENT_METHODS.ACCOUNT_TRANSFER) && (
          <ListItem
            name={"Transfer"}
            iconName={TRANSFER_ICON}
            onClick={() => onListItemPress(constants.PAY_WITH_ACCOUNT_TRANSFER)}
            className={getClass(constants.PAY_WITH_ACCOUNT_TRANSFER)}
          />
        )}
        {enabledPaymentMethods.includes(PAYMENT_METHODS.CARD) && (
          <ListItem
            iconName={CARD_ICON}
            name={"Card"}
            className={`${getClass(constants.PAY_WITH_CARD)}`}
            onClick={() => onListItemPress(constants.PAY_WITH_CARD)}
          />
        )}
        {enabledPaymentMethods.includes(PAYMENT_METHODS.USSD) && (
          <ListItem
            iconName={USSD_ICON}
            name={"USSD"}
            className={getClass(constants.PAY_WITH_USSD)}
            onClick={() => onListItemPress(constants.PAY_WITH_USSD)}
          />
        )}
        {enabledPaymentMethods.includes(PAYMENT_METHODS.PHONE_NUMBER) && (
          <ListItem
            iconName={PHONE_ICON}
            name={"Phone No."}
            className={getClass(constants.PAY_WITH_PHONE_NO)}
            onClick={() => onListItemPress(constants.PAY_WITH_PHONE_NO)}
          />
        )}
        {enabledPaymentMethods.includes(PAYMENT_METHODS.DIRECT_DEBIT) && (
          <ListItem
            name={"Bank"}
            iconName={BANK_ICON}
            className={getClass(constants.PAY_WITH_BANK)}
            onClick={() => onListItemPress(constants.PAY_WITH_BANK)}
          />
        )}
      </ul>
    </div>
  );
};

export default Menu;
