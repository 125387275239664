import * as React from "react";
import "./style.scss";
import Loader from "../../../component/loader";
// import MonnifyPaymentData from "./../../../services/data.service";
import { TRANSACTION_STATUS } from "../../../services/transaction-status.constant";
// import MonnifyConfig from "./../../../services/config.service";
import TransactionService from "../../../services/transaction.service";
import SockJsClient from "react-stomp";
import StoreContext from "../../../services/store/store-context";
import { STAGES as APP_STAGE } from "../../../App";
import constants from "../../../constants";
import { PAY_WITH_OTHER_METHODS } from "../../../services/constants";

const PAYMENT_POLLING_INTERVAL = 5000;
const WEBSOCKET_TRIAL_TIMES = 3;
class Secure3DIpgVerification extends React.Component {
  count = 0;
  constructor(props) {
    super(props);

    this.state = {
      loadingSecure3D: true,
      confirmingPaymentStatus: false,
      listeningForPayment: false,
      socketURL: null,
      socketTopics: [],
    };

    this.onFrameLoad = this.onFrameLoad.bind(this);
    this.iframe = React.createRef();
  }
  startPollingPaymentStatus() {
    console.log("called ooooo");
    this.paymentStatusIntervalId = setInterval(() => {
      this.checkPaymentStatus();
    }, PAYMENT_POLLING_INTERVAL);
  }

  checkPaymentStatus(onFailure) {
    TransactionService.queryTransactionStatus(
      this.context?.paymentInfo?.paymentData?.transactionReference,
      this.context?.paymentInfo?.paymentData?.apiKey,
      this.context?.paymentInfo?.configData?.apiUrl
    )
      .then((response) => {
        let responseData = (response && response.data) || {};
        if (
          !responseData ||
          !responseData.requestSuccessful ||
          !responseData.responseBody
        ) {
          //todo: handle error confirmation;
          //   onFailure && onFailure();
          //   this.onFlowComplete(TRANSACTION_STATUS.PENDING, {});
          return;
        }

        /*

        amount: 30
createdOn: "2022-04-22T18:42:52.000+0000"
currencyCode: "NGN"
customerEmail: "charles.onuorah@yahoo.com"
customerName: "Smart Mekiliuwa"
metaData: {deviceType: 'mobile', ipAddress: '127.0.0.1'}
paymentDescription: "Paying for Product A"
paymentMethod: "CARD"
paymentReference: "1650652970491"
paymentStatus: "PENDING"
transactionReference: "MNFY|48|20220422194252|000150"
        */

        let responseBody = responseData.responseBody;
        let transactionStatus =
          responseBody.paymentStatus || TRANSACTION_STATUS.EXPIRED;

        console.log("responseBody oooo: ", responseBody);

        if (
          transactionStatus === TRANSACTION_STATUS.SUCCESS ||
          transactionStatus === TRANSACTION_STATUS.PARTIALLY_PAID
        ) {
          this.stopPollingPaymentStatus();
          return this.onComplete(transactionStatus, responseBody);
        }
        // this.onFlowComplete(transactionStatus, responseBody);
      })
      .catch((error) => {
        let errorData = (error && error.response && error.response.data) || {};
        // let transactionStatus = errorData && errorData.paymentStatus;
        this.stopPollingPaymentStatus();
        this.onComplete(TRANSACTION_STATUS.FAILED, errorData);
      });
  }

  stopPollingPaymentStatus() {
    clearInterval(this.paymentStatusIntervalId);
  }

  componentWillUnmount() {
    this.stopWebSocket();
    this.stopPollingPaymentStatus();
  }

  componentDidUpdate() {
    this.count += 1;
    try {
      if (this.count === WEBSOCKET_TRIAL_TIMES) {
        this.iframe.current.remove();
        let request = {
          transactionReference: this.MonnifyPaymentData?.transactionReference,
          apiKey: this.MonnifyPaymentData?.apiKey,
          collectionChannel: this.MonnifyConfig?.configData?.collectionChannel,
        };
        TransactionService.authorizeSecure3d(
          request,
          this.MonnifyConfig?.configData?.apiUrl
        )
          .then((response) => {
            if (
              response.data.responseBody &&
              response.data.responseBody.status === TRANSACTION_STATUS.FAILED
            ) {
              return this.onComplete(
                TRANSACTION_STATUS.FAILED,
                response.data.responseBody
              );
            } else if (
              response.data.responseBody &&
              response.data.responseBody.status === TRANSACTION_STATUS.SUCCESS
            ) {
              return this.onComplete(
                TRANSACTION_STATUS.SUCCESS,
                response.data.responseBody
              );
            }
            this.startPollingPaymentStatus();
          })
          .catch((error) => {});
      }
    } catch (e) {}
  }
  componentDidMount() {
    this.MonnifyConfig = this.context?.paymentInfo || {};
    this.MonnifyPaymentData = this.context?.paymentInfo?.paymentData || {};
    this.form.submit();
    this.listenForCompletion();
  }

  getFormMethod() {
    if (!this.context?.cardDetails?.secure3dData) {
      return "POST";
    }

    return this.context?.cardDetails?.secure3dData?.method;
  }

  getFrameClass() {
    return this.state.loadingSecure3D || this.state.confirmingPaymentStatus
      ? "hide"
      : "show";
  }

  onSocketMessageReceived = (transactionResponse) => {
    let responseData = transactionResponse || {};
    if (!responseData.paymentStatus) {
      this.onComplete(TRANSACTION_STATUS.FAILED, {});
      return;
    }

    let transactionStatus = responseData.paymentStatus;
    this.onComplete(transactionStatus, responseData);
  };

  stopWebSocket = () => {
    this.setState({
      listeningForPayment: false,
      socketTopics: [],
      socketURL: null,
    });
    this.socketClientRef && this.socketClientRef.disconnect();
  };

  listenForCompletion = () => {
    if (this.state.listeningForPayment) {
      return;
    }
    let socketTopics = [];
    let url = `${this.MonnifyConfig.configData?.webSocketUrl}`;
    let topic = `/transaction/${this.MonnifyPaymentData.transactionReference}`;
    socketTopics.push(topic);
    this.setState({
      loadingSecure3D: false,
      listeningForPayment: true,
      socketTopics: socketTopics,
      socketURL: url,
    });
  };

  // onComplete(status, data) {
  //   this.props.onComplete && this.props.onComplete(status, data);
  // }

  onComplete(transactionStatus, data) {
    this.context.setPaymentCompleteInfo(data);
    this.stopPollingPaymentStatus();
    if (TRANSACTION_STATUS.FAILED === transactionStatus) {
      this.context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        data.message || data.responseMessage || "Transaction Failed",
        null,
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              this.context.changeTransactionStage(
                APP_STAGE.TRANSACTION_PROCESSING
              );
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                this.context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    } else if (TRANSACTION_STATUS.SUCCESS === transactionStatus) {
      this.context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
    }
  }

  onFrameLoad() {
    this.setState({ loadingSecure3D: false });
    this.listenForCompletion();
  }

  getWaitingText() {
    if (this.state.confirmingPaymentStatus) {
      return "Please wait while we confirm your payment status...";
    }

    if (this.state.loadingSecure3D) {
      return "Please wait while we take you to authorize your transaction...";
    }

    return "Waiting for Authorization...";
  }

  render() {
    let data = this.context?.cardDetails?.secure3dData || {};
    let url = data.url || data.redirectUrl;
    let md = data.md;
    let paReq = data.paReq;
    let termUrl = data.termUrl;
    return (
      <div>
        <div>
          <form
            action={url}
            method={this.getFormMethod()}
            ref={(f) => {
              this.form = f;
            }}
            target="secure3dFrame"
          >
            <input type="hidden" name="MD" value={md} />
            <input type="hidden" name="PaReq" value={paReq} />
            <input type="hidden" name="TermUrl" value={termUrl} />
          </form>
          <Loader text={this.getWaitingText()} />
        </div>

        <iframe
          title="Monnify Secure-3D Authorization"
          ref={this.iframe}
          className={this.getFrameClass()}
          id="secure3dFrame"
          name="secure3dFrame"
          onLoad={this.onFrameLoad}
        >
          {" "}
        </iframe>

        {this.state.listeningForPayment && (
          <SockJsClient
            url={this.state.socketURL}
            topics={this.state.socketTopics}
            onMessage={this.onSocketMessageReceived}
            ref={(client) => {
              this.socketClientRef = client;
            }}
          />
        )}
      </div>
    );
  }
}

Secure3DIpgVerification.contextType = StoreContext;

export default Secure3DIpgVerification;
