import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ClipBoard from "../../component/ClipBoard";
import MonnifyIcons from "../../icon";
import { ERROR_BANNER, PAY_WITH_OTHER_METHODS } from "../../services/constants";
import StoreContext from "../../services/store/store-context";
import { Button } from "../../styled-components/Input";
import { Banner } from "../transfer";
import Fade from "react-reveal/Fade";
import { SPINNER_WHITE } from "../../icon/icon";
import TransactionService from "../../services/transaction.service";
import {
  isTransactionSuccessful,
  TRANSACTION_STATUS,
} from "../../services/transaction-status.constant";
import { STAGES as APP_STAGE } from "../../App";
import SockJsClient from "react-stomp";
import { useParams } from "react-router-dom";
import constants from "../../constants";

const PaymentDetails = ({ changeBank }) => {
  const context = useContext(StoreContext);
  const [checkingPayment, setCheckingPayment] = useState(false);
  const [showError, setShowError] = useState(false);
  const [socketTopics, setSocketTopics] = useState("");
  const [socketUrl, setSocketUrl] = useState("");
  const [listenForPayment, setListForPayment] = useState(false);
  let socketClientRef = useRef();
  const [errorMessage, setErrorMessage] = useState(null);
  const params = useParams();
  const [messageReceived, setMessageReceived] = useState(null);
  const [paymentStatusIntervalId, setPaymentStatusIntervalId] = useState(null);

  const PAYMENT_STATUS_CONFIG = useMemo(
    () => ({
      poll: 15000,
      enableCheckPaymentButton: 180000,
      clearTimeOutForButton: 4000,
    }),
    []
  );

  const handleCheckPayment = async () => {
    try {
      setCheckingPayment(true);

      const response = await queryTransactionStatus();

      setCheckingPayment(false);
      if (response.transactionStatus === TRANSACTION_STATUS.PENDING) {
        setShowError(true);

        setErrorMessage(
          "Payment not received yet. Please dial the USSD string with your phone number to complete payment."
        );

        setTimeout(() => setShowError(false), 5000);
        // do something here for pending transactioon
      } else if (isTransactionSuccessful(response.transactionStatus)) {
        stopPollingPaymentStatus();
        onComplete(response.transactionStatus, response.responseBody);
      }
    } catch (error) {
      setCheckingPayment(false);
      let errorData = (error && error.response && error.response.data) || {};
      handleErrorLoadingAccount(errorData);
    }
  };

  const listenForPaymentOnSocket = useCallback(() => {
    let socketTopics = [];
    let url = `${context?.paymentInfo?.configData?.webSocketUrl}`;
    let topic = `/transaction/${params.id}`;
    socketTopics.push(topic);

    setSocketTopics(socketTopics);
    setSocketUrl(url);
    setListForPayment(true);
  }, [context?.paymentInfo?.configData?.webSocketUrl, params.id]);

  const checkPayment = async () => {
    try {
      const response = await queryTransactionStatus();

      if (response.transactionStatus === TRANSACTION_STATUS.PENDING) {
        // do something here for pending transactioon
      } else if (
        response.transactionStatus === TRANSACTION_STATUS.PAID ||
        response.transactionStatus === TRANSACTION_STATUS.SUCCESS
      ) {
        stopPollingPaymentStatus();
        onComplete(response.transactionStatus, response.responseBody);
      }

      // onComplete(response.transactionStatus, response.responseBody)
    } catch (error) {
      let errorData = (error && error.response && error.response.data) || {};
      handleErrorLoadingAccount(errorData);
    }
  };

  const startPollingPaymentStatus = (client) => {
    stopWebSocket();
    const id = setInterval(() => {
      checkPayment();
      //   if (client) client.connect();
    }, PAYMENT_STATUS_CONFIG.poll);
    setPaymentStatusIntervalId(id);
  };
  const stopPollingPaymentStatus = () => {
    clearInterval(paymentStatusIntervalId);
  };

  const queryTransactionStatus = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await TransactionService.queryUSSDTransactionStatus(
          context?.paymentInfo?.paymentData?.transactionReference,
          context?.paymentInfo?.configData?.apiUrl
        );
        let responseData = (response && response.data) || {};
        let responseBody = responseData.responseBody;
        let transactionStatus =
          responseBody.paymentStatus || TRANSACTION_STATUS.EXPIRED;
        resolve({ responseBody, transactionStatus });
      } catch (error) {
        let errorData = (error && error.response && error.response.data) || {};
        reject(errorData);
      }
    });
  };

  const handleErrorLoadingAccount = (data) => {
    let error =
      (data && data.responseMessage) || "Transaction could not be completed";
    setErrorMessage(error);
    context.changeTransactionStage(
      APP_STAGE.TRANSACTION_FAILED,
      error,
      "Transaction Failed",
      [
        {
          text: "Try again with USSD",
          onClickHanlder: () => {
            context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
          },
        },
        {
          text: PAY_WITH_OTHER_METHODS,
          onClickHanlder: () => {
            TransactionService.switchNextPaymentMethod(
              context,
              constants.PAY_WITH_USSD
            );
          },
        },
      ]
    );
  };

  const stopWebSocket = () => {
    setListForPayment(false);
    setSocketUrl("");
    setSocketTopics([]);
    socketClientRef && socketClientRef.disconnect();
  };

  const onSocketMessageReceived = (message) => {
    if (!message) {
      return;
    }

    if (message.paymentStatus === TRANSACTION_STATUS.PARTIALLY_PAID) {
      // todo: record received payment
      return;
    }
    setMessageReceived(true);
    stopWebSocket();

    onComplete(message.paymentStatus, message);
  };

  const onComplete = (status, data) => {
    context.setPaymentCompleteInfo(data);
    context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
  };

  useEffect(() => {
    listenForPaymentOnSocket();
    return () => stopPollingPaymentStatus();
  }, [listenForPaymentOnSocket]);

  return (
    <>
      {showError && (
        <div className="pb-2">
          <Fade top>
            <Banner type={ERROR_BANNER} text={errorMessage} />
          </Fade>
        </div>
      )}

      <h1 className="h-sub-title text-center ussd-bank-name ls-1 mb-0">
        {context?.paymentInfo?.paymentData?.bank?.name}{" "}
      </h1>
      <div className="py-1"></div>

      <div className="row">
        <div className="col-md-12">
          <h4 className="h-sub-title text-center">
            Dial the code below on your mobile phone to complete the payment
          </h4>
        </div>
      </div>
      <div className="py-2"></div>
      <div className="py-2"></div>
      <div className="ussd-code-container ls-1">
        <h3 className="ussdFont">
          {context?.paymentInfo?.paymentData?.ussdInfo?.paymentCode}
        </h3>
      </div>
      <div className="py-1"></div>
      <div className="row">
        <div className="col-md-12">
          <ClipBoard
            text={context?.paymentInfo?.paymentData?.ussdInfo?.paymentCode}
            defaultComponent={
              <h4 className="h-sub-title text-center cursor-pointer text-grey">
                Click to copy code
              </h4>
            }
          />
        </div>
      </div>
      <div className="py-2"></div>
      <div className="py-2"></div>
      <div className="row">
        <div className="col-md-12">
          <h4
            onClick={changeBank}
            style={{ fontSize: 12 }}
            className="h-title f-w-500 text-center cursor-pointer text-primary"
          >
            Select another bank
          </h4>
        </div>
      </div>
      <hr className="ussd-hr" />
      <Button
        onClick={handleCheckPayment}
        style={{ padding: checkingPayment ? "0px 8px" : "10px 8px" }}
      >
        {checkingPayment ? (
          <>
            {"Checking for payment"}
            <span>
              <MonnifyIcons type={SPINNER_WHITE} />
            </span>
          </>
        ) : (
          <>{`I’ve completed this payment`}</>
        )}
      </Button>
      {listenForPayment && (
        <SockJsClient
          url={socketUrl}
          topics={socketTopics}
          onMessage={onSocketMessageReceived}
          ref={(client) => {
            socketClientRef = client;
          }}
          autoReconnect={false}
          onConnect={() => {}}
          onDisconnect={() => {
            if (!messageReceived) startPollingPaymentStatus(socketClientRef);
          }}
        />
      )}
    </>
  );
};

export default PaymentDetails;
