import * as React from "react";
import "./style.scss";
import Loader from "../../../component/loader";
// import MonnifyPaymentData from "./../../../services/data.service";
import {
  isTransactionCompleted,
  isTransactionExpired,
  isTransactionFailed,
  isTransactionPending,
  isTransactionSuccessful,
  TRANSACTION_STATUS,
} from "../../../services/transaction-status.constant";
// import MonnifyConfig from "./../../../services/config.service";
import TransactionService from "../../../services/transaction.service";
import SockJsClient from "react-stomp";
import StoreContext from "../../../services/store/store-context";
import { STAGES as APP_STAGE } from "../../../App";
import constants from "../../../constants";
import { PAY_WITH_OTHER_METHODS } from "../../../services/constants";

const PAYMENT_POLLING_INTERVAL = 5000;
const SECURE3D_FRAME_REDIRECT_TIMES = 6;
class Secure3DIpgVerification extends React.Component {
  count = 0;
  constructor(props) {
    super(props);

    this.state = {
      loadingSecure3D: true,
      confirmingPaymentStatus: false,
      listeningForPayment: false,
      socketURL: null,
      socketTopics: [],
    };

    this.onFrameLoad = this.onFrameLoad.bind(this);
    this.iframe = React.createRef();
  }
  startPollingPaymentStatus() {
    this.paymentStatusIntervalId = setInterval(() => {
      this.checkPaymentStatus();
    }, PAYMENT_POLLING_INTERVAL);
  }

  checkPaymentStatus(onFailure) {
    TransactionService.queryTransactionStatus(
      this.context?.paymentInfo?.paymentData?.transactionReference,
      this.context?.paymentInfo?.paymentData?.apiKey,
      this.context?.paymentInfo?.configData?.apiUrl
    )
      .then((response) => {
        const transactionStatus = response.data.responseBody.status;
        const data = response.data.responseBody;
        if (isTransactionFailed(transactionStatus)) {
          this.stopPollingPaymentStatus();
          this.context.changeTransactionStage(
            APP_STAGE.TRANSACTION_FAILED,
            data.message || data.responseMessage || "Transaction Failed",
            null,
            [
              {
                text: "Try again with Card",
                onClickHanlder: () => {
                  this.context.changeTransactionStage(
                    APP_STAGE.TRANSACTION_PROCESSING
                  );
                },
              },
              {
                text: PAY_WITH_OTHER_METHODS,
                onClickHanlder: () => {
                  TransactionService.switchNextPaymentMethod(
                    this.context,
                    constants.PAY_WITH_CARD
                  );
                },
              },
            ]
          );
        } else if (isTransactionSuccessful(transactionStatus)) {
          this.stopPollingPaymentStatus();
          this.context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
        } else if (isTransactionExpired(transactionStatus)) {
          this.stopPollingPaymentStatus();
          this.context.changeTransactionStage(
            APP_STAGE.TRANSACTION_FAILED,
            data.message || data.responseMessage || "Transaction Expired",
            null,
            [
              {
                text: "Try again with Card",
                onClickHanlder: () => {
                  this.context.changeTransactionStage(
                    APP_STAGE.TRANSACTION_PROCESSING
                  );
                },
              },
              {
                text: PAY_WITH_OTHER_METHODS,
                onClickHanlder: () => {
                  TransactionService.switchNextPaymentMethod(
                    this.context,
                    constants.PAY_WITH_CARD
                  );
                },
              },
            ]
          );
        } else if (isTransactionCompleted(transactionStatus)) {
          this.stopPollingPaymentStatus();
          this.context.changeTransactionStage(
            APP_STAGE.TRANSACTION_FAILED,
            data.message || data.responseMessage || "Transaction Completed",
            null,
            [
              {
                text: "Try again with Card",
                onClickHanlder: () => {
                  this.context.changeTransactionStage(
                    APP_STAGE.TRANSACTION_PROCESSING
                  );
                },
              },
              {
                text: PAY_WITH_OTHER_METHODS,
                onClickHanlder: () => {
                  TransactionService.switchNextPaymentMethod(
                    this.context,
                    constants.PAY_WITH_CARD
                  );
                },
              },
            ]
          );
        } else if (isTransactionPending(transactionStatus)) {
          this.stopPollingPaymentStatus();
          this.context.changeTransactionStage(
            APP_STAGE.TRANSACTION_FAILED,
            data.message || data.responseMessage || "Transaction Pending",
            null,
            [
              {
                text: "Try again with Card",
                onClickHanlder: () => {
                  this.context.changeTransactionStage(
                    APP_STAGE.TRANSACTION_PROCESSING
                  );
                },
              },
              {
                text: PAY_WITH_OTHER_METHODS,
                onClickHanlder: () => {
                  TransactionService.switchNextPaymentMethod(
                    this.context,
                    constants.PAY_WITH_CARD
                  );
                },
              },
            ]
          );
        }
      })
      .catch((error) => {
        let errorData = (error && error.response && error.response.data) || {};
        if (!this.isObjectEmpty(errorData)) {
          if (isTransactionFailed(errorData?.responseBody?.status))
            return this.context.changeTransactionStage(
              APP_STAGE.TRANSACTION_FAILED,
              errorData.responseMessage,
              "Transaction Failed",
              [
                {
                  text: "Try again with Card",
                  onClickHanlder: () => {
                    this.context.changeTransactionStage(
                      APP_STAGE.TRANSACTION_PROCESSING
                    );
                  },
                },
                {
                  text: PAY_WITH_OTHER_METHODS,
                  onClickHanlder: () => {
                    TransactionService.switchNextPaymentMethod(
                      this.context,
                      constants.PAY_WITH_CARD
                    );
                  },
                },
              ]
            );

          if (isTransactionExpired(errorData?.responseBody?.status))
            return this.context.changeTransactionStage(
              APP_STAGE.TRANSACTION_FAILED,
              errorData.responseMessage,
              "Transaction Expired",
              [
                {
                  text: "Try again with Card",
                  onClickHanlder: () => {
                    this.context.changeTransactionStage(
                      this.STAGES.TRANSACTION_PROCESSING
                    );
                  },
                },
                {
                  text: PAY_WITH_OTHER_METHODS,
                  onClickHanlder: () => {
                    TransactionService.switchNextPaymentMethod(
                      this.context,
                      constants.PAY_WITH_CARD
                    );
                  },
                },
              ]
            );

          if (isTransactionPending(errorData?.responseBody?.status))
            return this.context.changeTransactionStage(
              APP_STAGE.TRANSACTION_FAILED,
              errorData.responseMessage,
              "Transaction Pending",
              [
                {
                  text: "Try again with Card",
                  onClickHanlder: () => {
                    this.context.changeTransactionStage(
                      APP_STAGE.TRANSACTION_PROCESSING
                    );
                  },
                },
                {
                  text: PAY_WITH_OTHER_METHODS,
                  onClickHanlder: () => {
                    TransactionService.switchNextPaymentMethod(
                      this.context,
                      constants.PAY_WITH_CARD
                    );
                  },
                },
              ]
            );

          this.context.changeTransactionStage(
            APP_STAGE.TRANSACTION_FAILED,
            errorData.responseMessage || "Unable to complete transaction",
            "Transaction Failed",
            [
              {
                text: "Try again with Card",
                onClickHanlder: () => {
                  this.context.changeTransactionStage(
                    APP_STAGE.TRANSACTION_PROCESSING
                  );
                },
              },
              {
                text: PAY_WITH_OTHER_METHODS,
                onClickHanlder: () => {
                  TransactionService.switchNextPaymentMethod(
                    this.context,
                    constants.PAY_WITH_CARD
                  );
                },
              },
            ]
          );
        }
      });
  }

  isObjectEmpty = (object) => Object.keys(object).length === 0;

  stopPollingPaymentStatus() {
    clearInterval(this.paymentStatusIntervalId);
    TransactionService.cancelAllRequest();
  }

  componentWillUnmount() {
    this.stopWebSocket();
    this.stopPollingPaymentStatus();
  }

  componentDidUpdate() {
    this.count += 1;
    try {
      if (this.count === SECURE3D_FRAME_REDIRECT_TIMES) {
        let request = {
          transactionReference: this.MonnifyPaymentData?.transactionReference,
          apiKey: this.MonnifyPaymentData?.apiKey,
          collectionChannel: this.MonnifyConfig?.configData?.collectionChannel,
        };
        TransactionService.authorizeSecure3d(
          request,
          this.MonnifyConfig?.configData?.apiUrl
        )
          .then((response) => {
            const transactionStatus = response.data.responseBody.status;
            const data = response.data.responseBody;
            if (isTransactionFailed(transactionStatus)) {
              return this.context.changeTransactionStage(
                APP_STAGE.TRANSACTION_FAILED,
                data.message || data.responseMessage || "Transaction Failed",
                null,
                [
                  {
                    text: "Try again with Card",
                    onClickHanlder: () => {
                      this.context.changeTransactionStage(
                        APP_STAGE.TRANSACTION_PROCESSING
                      );
                    },
                  },
                  {
                    text: PAY_WITH_OTHER_METHODS,
                    onClickHanlder: () => {
                      TransactionService.switchNextPaymentMethod(
                        this.context,
                        constants.PAY_WITH_CARD
                      );
                    },
                  },
                ]
              );
            } else if (isTransactionSuccessful(transactionStatus)) {
              return this.context.changeTransactionStage(
                APP_STAGE.TRANSACTION_SUCCESSFUL
              );
            } else if (isTransactionExpired(transactionStatus)) {
              return this.context.changeTransactionStage(
                APP_STAGE.TRANSACTION_FAILED,
                data.message || data.responseMessage || "Transaction Expired",
                null,
                [
                  {
                    text: "Try again with Card",
                    onClickHanlder: () => {
                      this.context.changeTransactionStage(
                        APP_STAGE.TRANSACTION_PROCESSING
                      );
                    },
                  },
                  {
                    text: PAY_WITH_OTHER_METHODS,
                    onClickHanlder: () => {
                      TransactionService.switchNextPaymentMethod(
                        this.context,
                        constants.PAY_WITH_CARD
                      );
                    },
                  },
                ]
              );
            } else if (isTransactionCompleted(transactionStatus)) {
              return this.context.changeTransactionStage(
                APP_STAGE.TRANSACTION_FAILED,
                data.message || data.responseMessage || "Transaction Completed",
                null,
                [
                  {
                    text: "Try again with Card",
                    onClickHanlder: () => {
                      this.context.changeTransactionStage(
                        APP_STAGE.TRANSACTION_PROCESSING
                      );
                    },
                  },
                  {
                    text: PAY_WITH_OTHER_METHODS,
                    onClickHanlder: () => {
                      TransactionService.switchNextPaymentMethod(
                        this.context,
                        constants.PAY_WITH_CARD
                      );
                    },
                  },
                ]
              );
            } else if (isTransactionPending(transactionStatus)) {
              return this.context.changeTransactionStage(
                APP_STAGE.TRANSACTION_FAILED,
                data.message || data.responseMessage || "Transaction Pending",
                null,
                [
                  {
                    text: "Try again with Card",
                    onClickHanlder: () => {
                      this.context.changeTransactionStage(
                        APP_STAGE.TRANSACTION_PROCESSING
                      );
                    },
                  },
                  {
                    text: PAY_WITH_OTHER_METHODS,
                    onClickHanlder: () => {
                      TransactionService.switchNextPaymentMethod(
                        this.context,
                        constants.PAY_WITH_CARD
                      );
                    },
                  },
                ]
              );
            }
            return this.startPollingPaymentStatus();
          })
          .catch((error) => {
            let errorData =
              (error && error.response && error.response.data) || {};

            if (!this.isObjectEmpty(errorData)) {
              if (isTransactionFailed(errorData?.responseBody?.status))
                return this.context.changeTransactionStage(
                  APP_STAGE.TRANSACTION_FAILED,
                  errorData.responseMessage,
                  "Transaction Failed",
                  [
                    {
                      text: "Try again with Card",
                      onClickHanlder: () => {
                        this.context.changeTransactionStage(
                          APP_STAGE.TRANSACTION_PROCESSING
                        );
                      },
                    },
                    {
                      text: PAY_WITH_OTHER_METHODS,
                      onClickHanlder: () => {
                        TransactionService.switchNextPaymentMethod(
                          this.context,
                          constants.PAY_WITH_CARD
                        );
                      },
                    },
                  ]
                );

              if (isTransactionExpired(errorData?.responseBody?.status))
                return this.context.changeTransactionStage(
                  APP_STAGE.TRANSACTION_FAILED,
                  errorData.responseMessage,
                  "Transaction Expired",
                  [
                    {
                      text: "Try again with Card",
                      onClickHanlder: () => {
                        this.context.changeTransactionStage(
                          this.STAGES.TRANSACTION_PROCESSING
                        );
                      },
                    },
                    {
                      text: PAY_WITH_OTHER_METHODS,
                      onClickHanlder: () => {
                        TransactionService.switchNextPaymentMethod(
                          this.context,
                          constants.PAY_WITH_CARD
                        );
                      },
                    },
                  ]
                );

              if (isTransactionPending(errorData?.responseBody?.status))
                return this.context.changeTransactionStage(
                  APP_STAGE.TRANSACTION_FAILED,
                  errorData.responseMessage,
                  "Transaction Pending",
                  [
                    {
                      text: "Try again with Card",
                      onClickHanlder: () => {
                        this.context.changeTransactionStage(
                          APP_STAGE.TRANSACTION_PROCESSING
                        );
                      },
                    },
                    {
                      text: PAY_WITH_OTHER_METHODS,
                      onClickHanlder: () => {
                        TransactionService.switchNextPaymentMethod(
                          this.context,
                          constants.PAY_WITH_CARD
                        );
                      },
                    },
                  ]
                );

              this.context.changeTransactionStage(
                APP_STAGE.TRANSACTION_FAILED,
                errorData.responseMessage || "Unable to complete transaction",
                "Transaction Failed",
                [
                  {
                    text: "Try again with Card",
                    onClickHanlder: () => {
                      this.context.changeTransactionStage(
                        APP_STAGE.TRANSACTION_PROCESSING
                      );
                    },
                  },
                  {
                    text: PAY_WITH_OTHER_METHODS,
                    onClickHanlder: () => {
                      TransactionService.switchNextPaymentMethod(
                        this.context,
                        constants.PAY_WITH_CARD
                      );
                    },
                  },
                ]
              );
            }
          });
      }
    } catch (e) {}
  }
  componentDidMount() {
    this.startPollingPaymentStatus();
    this.MonnifyConfig = this.context?.paymentInfo || {};
    this.MonnifyPaymentData = this.context?.paymentInfo?.paymentData || {};
    this.form.submit();
    this.listenForCompletion();
  }

  getFormMethod() {
    if (!this.context?.cardDetails?.secure3dData) {
      return "POST";
    }

    return this.context?.cardDetails?.secure3dData?.method;
  }

  getFrameClass() {
    return this.state.loadingSecure3D || this.state.confirmingPaymentStatus
      ? "hide"
      : "show";
  }

  onSocketMessageReceived = (transactionResponse) => {
    let responseData = transactionResponse || {};
    if (!responseData.paymentStatus) {
      this.onComplete(TRANSACTION_STATUS.FAILED, {});
      return;
    }

    let transactionStatus = responseData.paymentStatus;
    this.onComplete(transactionStatus, responseData);
  };

  stopWebSocket = () => {
    this.setState({
      listeningForPayment: false,
      socketTopics: [],
      socketURL: null,
    });
    this.socketClientRef && this.socketClientRef.disconnect();
  };

  listenForCompletion = () => {
    if (this.state.listeningForPayment) {
      return;
    }
    let socketTopics = [];
    let url = `${this.MonnifyConfig.configData?.webSocketUrl}`;
    let topic = `/transaction/${this.MonnifyPaymentData.transactionReference}`;
    socketTopics.push(topic);
    this.setState({
      loadingSecure3D: false,
      listeningForPayment: true,
      socketTopics: socketTopics,
      socketURL: url,
    });
  };

  // onComplete(status, data) {
  //   this.props.onComplete && this.props.onComplete(status, data);
  // }

  onComplete(transactionStatus, data) {
    this.context.setPaymentCompleteInfo(data);
    this.stopPollingPaymentStatus();
    if (isTransactionFailed(transactionStatus)) {
      this.context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        data.message || data.responseMessage || "Transaction Failed",
        null,
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              this.context.changeTransactionStage(
                APP_STAGE.TRANSACTION_PROCESSING
              );
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                this.context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    } else if (isTransactionSuccessful(transactionStatus)) {
      this.context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
    } else if (isTransactionExpired(transactionStatus)) {
      this.context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        data.message || data.responseMessage || "Transaction Expired",
        null,
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              this.context.changeTransactionStage(
                APP_STAGE.TRANSACTION_PROCESSING
              );
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                this.context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    } else if (isTransactionCompleted(transactionStatus)) {
      this.context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        data.message || data.responseMessage || "Transaction Completed",
        null,
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              this.context.changeTransactionStage(
                APP_STAGE.TRANSACTION_PROCESSING
              );
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                this.context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    } else if (isTransactionPending(transactionStatus)) {
      this.context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        data.message || data.responseMessage || "Transaction Pending",
        null,
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              this.context.changeTransactionStage(
                APP_STAGE.TRANSACTION_PROCESSING
              );
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                this.context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    }
  }

  onFrameLoad() {
    this.setState({ loadingSecure3D: false });
    this.listenForCompletion();
  }

  getWaitingText() {
    if (this.state.confirmingPaymentStatus) {
      return "Please wait while we confirm your payment status...";
    }

    if (this.state.loadingSecure3D) {
      return "Please wait while we take you to authorize your transaction...";
    }

    return "Waiting for Authorization...";
  }

  render() {
    let data = this.context?.cardDetails?.secure3dData || {};
    let url = data.url || data.redirectUrl;
    let md = data.md;
    let paReq = data.paReq;
    let termUrl = data.termUrl;
    let jwt = data.jwt;
    console.log("data> ", data);
    return (
      <div>
        <div>
          <form
            action={url}
            method={this.getFormMethod()}
            ref={(f) => {
              this.form = f;
            }}
            target="secure3dFrame"
          >
            <input type="hidden" name="MD" value={md} />
            <input type="hidden" name="JWT" value={jwt} placeholder="JWT" />
            <input type="hidden" name="PaReq" value={paReq} />
            <input type="hidden" name="TermUrl" value={termUrl} />
          </form>
          <Loader text={this.getWaitingText()} />
        </div>

        <iframe
          allow="cross-origin"
          title="Monnify Secure-3D Authorization"
          ref={this.iframe}
          className={this.getFrameClass()}
          id="secure3dFrame"
          name="secure3dFrame"
          sandbox="allow-forms allow-scripts allow-same-origin allow-popups allow-pointer-lock"
          onLoad={this.onFrameLoad}
        >
          {" "}
        </iframe>

        {this.state.listeningForPayment && (
          <SockJsClient
            url={this.state.socketURL}
            topics={this.state.socketTopics}
            onMessage={this.onSocketMessageReceived}
            ref={(client) => {
              this.socketClientRef = client;
            }}
          />
        )}
      </div>
    );
  }
}

Secure3DIpgVerification.contextType = StoreContext;

export default Secure3DIpgVerification;
