import styled from "styled-components";

export const Input = styled.input`
border: ${(props) => (props.border ? props.border : "none")};
width: ${(props) => (props.width ? props.width : "100%")};
padding: 18px 8px 6px 8px;
margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "0")};
border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "0")};
font-family:"Inter", sans-serif;
text-align: ${(props) => (props.pin ? "center" : "left")};
position: relative;
&:focus{
    outline: 0;
}
&::placeholder{
    color: #d3d3d3;
    font-size: 12px;
}
&:after{
    content: "Email";
    position: absolute;
    top: 0;
    left: 0;
    color: #000;
    font-size: 122px;
}
}
`;

export const TextInput = styled(Input)``;

export const PinInput = styled(TextInput)`
  padding: 12px 8px 12px 8px !important;
`;

export const Button = styled.button.attrs((props) => ({
  disabled: props.disabled,
}))`
  background: ${(props) =>
    props.disabled
      ? "#E0E0E0"
      : "linear-gradient(147.87deg, #F0AA22 -8.91%, #F05822 99.52%)"};
  border-radius: 4px;
  color: #fff;
  padding: 10px 8px;
  border: none;
  font-size: 14px;
  height: 50px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: ${(props) => (props.width ? props.width : "100%")};
`;
