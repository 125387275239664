import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SelectInput from "../../component/select";
import MonnifyIcons from "../../icon";
import { GREEN_CHECK_ICON, SPINNER_WHITE } from "../../icon/icon";
import StoreContext from "../../services/store/store-context";
import TransactionService from "../../services/transaction.service";
import { Button, TextInput } from "../../styled-components/Input";
import Fade from "react-reveal/Fade";
import { Banner } from "../transfer";
import { ERROR_BANNER } from "../../services/constants";
import "./index.scss";

const EnterAccountNumber = ({ resetStage, onContinue }) => {
  const context = useContext(StoreContext);
  const [accountNumber, setAccountNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isNameLoaded, setNameLoaded] = useState(false);
  const [accountName, setAccountName] = useState("");

  const handleOnChange = (e) => {
    if (isNameLoaded) {
      setNameLoaded(false);
      setAccountName("");
    }
    setAccountNumber(e.target.value);
  };
  const params = useParams();
  const getAccountName = async () => {
    try {
      setErrorMessage(null);
      if (accountNumber.trim() === "")
        return setErrorMessage("Please provide account number");
      setCheckingPayment(true);

      setNameLoaded(false);
      setAccountName("");
      const response = await TransactionService.verifyBankTransaction(
        {
          transactionReference: params.id,
          accountNumber: accountNumber,
          bankCode: context?.paymentInfo?.paymentData?.bankUssdCode,
        },
        context?.paymentInfo?.configData?.apiUrl
      );
      setCheckingPayment(false);
      const responseBody = response.data.responseBody || {};

      setAccountName(responseBody.accountName);

      setNameLoaded(true);

      context.updatePaymentData({
        paymentData: {
          ...context?.paymentInfo?.paymentData,
          accountName,
          accountNumber,
        },
      });
    } catch (error) {
      setCheckingPayment(false);
      const errorData = (error && error.response && error.response.data) || {};

      setErrorMessage(errorData.responseMessage);
    }
  };
  const [checkingPayment, setCheckingPayment] = useState(false);

  const getText = () => {
    return isNameLoaded ? "Proceed" : "Continue";
  };

  const [isActive, setActive] = useState(false);

  const handleOnNextPress = () => {
    if (accountNumber.trim() !== "" && accountNumber.length === 10) {
      if (!isNameLoaded) return getAccountName();
      // go to next

      return onContinue();
    }
  };

  useEffect(() => {
    if (accountNumber.length === 10) {
      return setActive(true);
    }
    return setActive(false);
  }, [accountNumber]);
  const isCopyOrPasteAction = (e) => {
    return (
      (e.ctrlKey || e.metaKey) &&
      (e.code === "KeyC" || e.code === "KeyV" || e.code === 'code: "KeyX"')
    );
  };
  const accpetNumbersOnly = (e) => {
    const ARROW_KEYS = [37, 38, 39, 40];
    const BACK_SPACE = 8;

    // 48 - 57 keycode for numbers

    if (isCopyOrPasteAction(e)) return;
    else if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      e.keyCode === BACK_SPACE ||
      ARROW_KEYS.includes(e.keyCode)
    ) {
      // 0-9 only
      if (e.keyCode === BACK_SPACE) return;
      if (accountNumber.length === 10) {
        return e.preventDefault();
      }
      return;
    } else if (accountNumber.length === 10) {
      return e.preventDefault();
    }
    e.preventDefault();
  };

  return (
    <>
      {errorMessage && (
        <>
          <Fade top>
            <Banner type={ERROR_BANNER} text={errorMessage} />
          </Fade>
          <div className="py-2"></div>
        </>
      )}
      <div className="row">
        <div className="col-md-12 no-horizontal-padding">
          <h3 className="text-center sub-title text-black">
            Enter your account number
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 no-horizontal-padding">
          <SelectInput
            placeholder={"Choose One"}
            value={context?.paymentInfo?.paymentData?.bank?.code}
            disabled
            options={[context?.paymentInfo?.paymentData?.bank].map((item) => ({
              label: item.name,
              value: item.code,
            }))}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 no-horizontal-padding">
          <div className="pan-wrapper">
            <label>Account number</label>
            <div className="pan-input-wrapper">
              <TextInput
                name="accountNumber"
                onChange={handleOnChange}
                placeholder="0000000000"
                type="text"
                value={accountNumber}
                onKeyDown={accpetNumbersOnly}
              />
            </div>
          </div>
        </div>
      </div>
      {isNameLoaded && (
        <div className="row">
          <div className="col-md-12 no-horizontal-padding">
            <div className="green-notify-banner">
              <span>
                <MonnifyIcons type={GREEN_CHECK_ICON} />
              </span>
              <article>{accountName}</article>
            </div>
          </div>
        </div>
      )}

      <div className="row mt-4">
        <div className="col-md-12 no-horizontal-padding">
          <Button
            disabled={!isActive}
            onClick={handleOnNextPress}
            style={{ padding: checkingPayment ? "0px 8px" : "10px 8px" }}
          >
            {checkingPayment ? (
              <>
                {"Validating details"}
                <span>
                  <MonnifyIcons type={SPINNER_WHITE} />
                </span>
              </>
            ) : (
              <>{getText()}</>
            )}
          </Button>
        </div>
      </div>
      <div className="d-flex mt-4 justify-content-center w-100">
        <span className="cursor-pointer">
          {/* <MonnifyIcons type={START_ALL_OVER_ICON} /> */}
          <h2 onClick={resetStage} className="go-back-text">
            Go back
          </h2>
        </span>
      </div>
    </>
  );
};

export default EnterAccountNumber;
