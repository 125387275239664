import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  formastAsExpiryCardNumber,
  formatAsCardNumber,
  removeSlashCharacter,
  removeSpaces,
} from "../../services/utils.service";
import { Button, PinInput, TextInput } from "../../styled-components/Input";
import CardValidator from "card-validator";
import TransactionService from "../../services/transaction.service";
import StoreContext from "../../services/store/store-context";
import { useParams } from "react-router-dom";
import {
  isOtpAuthorizationRequired,
  isBankAuthorizationRequired,
  isTransactionFailed,
  isMastercardAuthorizationRequired,
  isTransactionSuccessful,
} from "../../services/transaction-status.constant";
import MonnifyIcons from "../../icon";
import { ASK_ICON, SPINNER_WHITE } from "../../icon/icon";
import { STAGES as PAY_WITH_CARD_STAGE } from ".";
import { STAGES as APP_STAGE } from "../../App";
import { Banner } from "../transfer";
import Fade from "react-reveal/Fade";
import { ERROR_BANNER, PAY_WITH_OTHER_METHODS } from "../../services/constants";
import constants from "../../constants";

CardValidator.creditCardType.addCard({
  niceType: "Verve Card",
  type: "verve",
  patterns: [
    [506099, 506198],
    [650002, 650027],
  ],
  gaps: [4, 8, 12, 16],
  lengths: [19, 16],
  code: {
    name: "CVV",
    size: 3,
  },
});

CardValidator.creditCardType.changeOrder("verve", 0);
CardValidator.creditCardType.removeCard("maestro");
CardValidator.creditCardType.removeCard("american-express");
CardValidator.creditCardType.removeCard("diners-club");
CardValidator.creditCardType.removeCard("discover");
CardValidator.creditCardType.removeCard("jcb");
CardValidator.creditCardType.removeCard("unionpay");
CardValidator.creditCardType.removeCard("elo");
CardValidator.creditCardType.removeCard("mir");
CardValidator.creditCardType.removeCard("hiper");
CardValidator.creditCardType.removeCard("hipercard");

const CardDetails = ({ onNextPress, onComplete }) => {
  const context = useContext(StoreContext);
  const params = useParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");

  const pinRef1 = useRef();
  const pinRef2 = useRef();
  const pinRef3 = useRef();
  const pinRef4 = useRef();

  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCVV] = useState("");
  const [pin, setPin] = useState("");
  const [isPinRequired, setPinRequired] = useState(false);

  const [checkingPayment, setCheckPayment] = useState(false);

  const [shouldDisableActionButton, setShouldDisableActionButton] =
    useState(true);

  const [Validation, setValidation] = useState({});

  const isCardNumberValid = useCallback(() => {
    return cardNumber.replace(/\s/g, "").length >= 16;
  }, [cardNumber]);

  const isExpiryDateValid = useCallback(() => {
    return expiryDate.trim() !== "";
  }, [expiryDate]);
  const isCvvValid = useCallback(() => {
    return cvv.trim().length === 3;
  }, [cvv]);
  const isPinValid = useCallback(() => {
    return pin.trim().length === 4;
  }, [pin]);
  const evaluateCardType = useCallback(
    (callback) => {
      if (!callback) {
        callback = () => {};
      }

      let detectedCardType = CardValidator.number(removeSpaces(cardNumber));
      detectedCardType = detectedCardType || {};
      detectedCardType.card = detectedCardType.card || {};

      let maxLength = Math.max(detectedCardType.card.lengths) || 19;
      if (maxLength === 16) {
        detectedCardType.maxLength = 16;
        detectedCardType.maxLengthWithPadding = 19;
      } else if (maxLength === 19) {
        detectedCardType.maxLength = 19;
        detectedCardType.maxLengthWithPadding = 23;
      }
      // this.setState({Validation: detectedCardType}, callback);

      setValidation(detectedCardType);
    },
    [setValidation, cardNumber]
  );
  const handleOnChange = (e) => {
    const {
      target: { name, value },
    } = e;

    if (name === "cardNumber") {
      let stripedValue = removeSpaces(value);
      let newValue = formatAsCardNumber(stripedValue);

      if (
        removeSpaces(cardNumber).length === Validation?.maxLength &&
        stripedValue.length >= Validation?.maxLength
      )
        return;
      setCardNumber(newValue ? newValue : "");
    } else if (name === "expiryDate") {
      const stripedValue = removeSlashCharacter(value);
      let newValue = formastAsExpiryCardNumber(stripedValue);
      if (
        removeSlashCharacter(expiryDate).length === 4 &&
        stripedValue.length >= 4
      )
        return;
      setExpiryDate(newValue ? newValue : "");
    } else if (name === "cvv") {
      if (cvv?.trim().length === 3 && value.length >= 3) return;
      setCVV(value);
    } else if (name === "pin1") {
      if (pin1?.trim().length === 1 && value.length >= 1) {
        return pinRef2.current?.focus();
      } else if (value.trim() === "") {
        setPin1(value);
      } else {
        setPin1(value);
        return pinRef2.current?.focus();
      }
    } else if (name === "pin2") {
      if (pin2?.trim().length === 1 && value.length >= 1) {
        return pinRef3.current?.focus();
      } else {
        setPin2(value);

        return value.trim() !== ""
          ? pinRef3.current?.focus()
          : pinRef1.current?.focus();
      }
    } else if (name === "pin3") {
      if (pin3?.trim().length === 1 && value.length >= 1) {
        return pinRef4.current?.focus();
      } else {
        setPin3(value);

        return value.trim() !== ""
          ? pinRef4.current?.focus()
          : pinRef2.current?.focus();
      }
    } else if (name === "pin4") {
      if (pin4?.trim().length === 1 && value.length >= 1) {
        return;
      } else {
        setPin4(value);

        return value.trim() !== "" ? null : pinRef3.current?.focus();
      }
    }
  };
  const isCopyOrPasteAction = (e) => {
    return (
      (e.ctrlKey || e.metaKey) &&
      (e.code === "KeyC" || e.code === "KeyV" || e.code === 'code: "KeyX"')
    );
  };
  const accpetNumbersOnly = (e) => {
    const ARROW_KEYS = [37, 38, 39, 40];
    if (isCopyOrPasteAction(e)) return;
    if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      e.keyCode === 8 ||
      ARROW_KEYS.includes(e.keyCode)
    ) {
      // 0-9 only
      return;
    }
    e.preventDefault();
  };

  const isInfoComplete = useCallback(() => {
    if (
      isCardNumberValid() &&
      isExpiryDateValid() &&
      isCvvValid() &&
      !isPinRequired
    ) {
      console.log("called ooooo>>> ");
      return setShouldDisableActionButton(false);
    } else if (
      isCardNumberValid() &&
      isExpiryDateValid() &&
      isCvvValid() &&
      isPinValid()
    )
      return setShouldDisableActionButton(false);

    setShouldDisableActionButton(true);
  }, [
    isCardNumberValid,
    isCvvValid,
    isExpiryDateValid,
    isPinValid,
    isPinRequired,
  ]);

  const pinChange = useCallback(() => {
    setPin(`${pin1}${pin2}${pin3}${pin4}`);
    isInfoComplete();
  }, [pin1, pin2, pin3, pin4, isInfoComplete]);

  useEffect(() => {
    isInfoComplete();
  }, [cardNumber, cvv, expiryDate, pin, isInfoComplete]);

  useEffect(() => {
    pinChange();
  }, [pin1, pin2, pin3, pin4, pinChange]);

  useEffect(() => {
    evaluateCardType();
  }, [evaluateCardType, cardNumber]);

  useEffect(() => {
    // if format === 02/01/20
    if (expiryDate.length >= 8) {
      const dateParts = expiryDate.split("/");
      const month = dateParts[0];
      const year = dateParts[dateParts.length - 1];

      setExpiryDate(`${month}/${year}`);
    }
  }, [expiryDate]);

  const getCardDetails = useCallback(() => {
    if (removeSpaces(cardNumber).length >= 16) {
      TransactionService.getCardRequirements({
        cardNumber: removeSpaces(cardNumber),
        collectionChannel: context?.paymentInfo?.configData?.collectionChannel,
        transactionReference: params.id,
        domain: context?.paymentInfo?.configData?.apiUrl,
      })
        .then((response) => {
          let cardRequirement = { requirePin: true };
          let responseData = (response && response.data) || {};

          if (
            responseData &&
            responseData.requestSuccessful &&
            responseData.responseBody
          ) {
            cardRequirement = responseData.responseBody || { requirePin: true };
          }
          if (cardRequirement.requirePin !== isPinRequired)
            setPinRequired(cardRequirement.requirePin);
        })
        .catch((error) => {
          setPinRequired(false);
        });
    } else if (cardNumber.length === 0) {
      setPinRequired(false);
    }
  }, [
    setPinRequired,
    cardNumber,
    params.id,
    context?.paymentInfo?.configData?.apiUrl,
    context?.paymentInfo?.configData?.collectionChannel,
    isPinRequired,
  ]);

  useEffect(() => {
    getCardDetails();
  }, [getCardDetails]);

  const handleOnNextPress = async () => {
    try {
      if (checkingPayment) return;
      const dateParts = expiryDate.split("/");
      const cardInfo = {
        number: removeSpaces(cardNumber),
        cvv,
        expiryMonth: dateParts[0],
        expiryYear: `20${dateParts[dateParts.length - 1]}`,
        pin: isPinRequired ? `${pin1}${pin2}${pin3}${pin4}` : null,
      };

      setCheckPayment(true);
      setErrorMessage(null);

      const response = await TransactionService.chargeCard(
        {
          transactionReference: params.id,
          apiKey: context?.paymentInfo?.configData?.apiKey,
          collectionChannel:
            context?.paymentInfo?.configData?.collectionChannel,
          card: cardInfo,
        },
        context?.paymentInfo?.configData.apiUrl
      );

      setCheckPayment(false);

      const responseData = (response && response.data) || {};

      let paymentStatus = responseData.responseBody.status;

      const tokenId = responseData?.responseBody?.otpData?.id;

      const secure3dData = responseData?.responseBody?.secure3dData;

      context.updateCardDetails({
        ...cardInfo,
        tokenId,
        secure3dData,
        message: responseData?.responseBody.message,
      });
      if (isTransactionSuccessful(paymentStatus)) {
        return onComplete(paymentStatus, responseData?.responseBody);
      }
      if (isTransactionFailed(responseData?.responseBody?.status)) {
        return context.changeTransactionStage(
          APP_STAGE.TRANSACTION_FAILED,
          responseData?.responseBody?.message,
          null,
          [
            {
              text: "Try again with Card",
              onClickHanlder: () => {
                context.changeTransactionStage(
                  APP_STAGE.TRANSACTION_PROCESSING
                );
              },
            },
            {
              text: PAY_WITH_OTHER_METHODS,
              onClickHanlder: () => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_CARD
                );
              },
            },
          ]
        );
      }

      if (isOtpAuthorizationRequired(paymentStatus)) {
        onNextPress();
      }

      if (isBankAuthorizationRequired(paymentStatus)) {
        if (
          secure3dData.termUrl &&
          secure3dData.termUrl.indexOf("interswitchng") >= 0
        ) {
          return onNextPress(PAY_WITH_CARD_STAGE.SECURE_3D_IPG_AUTHORIZATION);
        }
        return onNextPress(PAY_WITH_CARD_STAGE.SECURE_3D_AUTHORIZATION);
      }
      if (isMastercardAuthorizationRequired(paymentStatus)) {
        return onNextPress(PAY_WITH_CARD_STAGE.MASTER_CARD_AUTHORIZATION);
      }
    } catch (error) {
      setCheckPayment(false);

      let errorData = (error && error.response && error.response.data) || {};

      setErrorMessage(errorData.responseMessage);
      let transactionStatus = errorData && errorData.status;
      onComplete(transactionStatus, errorData);
    }
  };

  const getCardType = () => {
    return Validation?.card?.type;
  };

  return (
    <>
      {errorMessage && (
        <>
          <Fade top>
            <Banner type={ERROR_BANNER} text={errorMessage} />
          </Fade>
          <div className="py-1"></div>
        </>
      )}

      <div className="row">
        <div className="col-md-12">
          <h3 className="text-center sub-title text-black">
            Enter your card details
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 no-horizontal-padding">
          <div className="pan-wrapper">
            <label>Card Number</label>
            <div className="pan-input-wrapper">
              <TextInput
                onKeyDown={accpetNumbersOnly}
                value={cardNumber}
                name="cardNumber"
                onChange={handleOnChange}
                type="text"
                placeholder="0000 0000 0000 0000"
              />
            </div>
            <span className={`card-type ${getCardType()}`}></span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 no-horizontal-padding">
          <div className="d-flex">
            <div
              className="pan-wrapper no-right-border"
              style={{ width: "50%" }}
            >
              <label>Expiry Date</label>
              <div className="pan-input-wrapper">
                <TextInput
                  onKeyDown={accpetNumbersOnly}
                  value={expiryDate}
                  name="expiryDate"
                  onChange={handleOnChange}
                  placeholder="01/12"
                  type="text"
                />
              </div>
            </div>
            <div
              className="pan-wrapper no-left-border"
              style={{ width: "50%" }}
            >
              <label>CVV</label>
              <div className="pan-input-wrapper">
                <TextInput
                  onKeyDown={accpetNumbersOnly}
                  value={cvv}
                  name="cvv"
                  onChange={handleOnChange}
                  type="text"
                  placeholder="000"
                />
              </div>
              <span className={`card-type cursor-pointer`}>
                <span className="custom-tooltip">
                  <MonnifyIcons type={ASK_ICON} />
                  <span className="tooltiptext"></span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      {isPinRequired && (
        <Fade bottom>
          <div className="py-2"></div>
          <h3 className="text-grey  text-black text-center">
            Enter your Card PIN
          </h3>
          <div className="row">
            <div className="col-md-12 no-horizontal-padding">
              <div className="d-flex">
                <div
                  className="pan-wrapper no-right-border"
                  style={{ width: "25%" }}
                >
                  <div className="pan-input-wrapper">
                    <PinInput
                      ref={pinRef1}
                      value={pin1}
                      name="pin1"
                      onChange={handleOnChange}
                      onKeyDown={accpetNumbersOnly}
                      pin
                      type="password"
                    />
                  </div>
                </div>
                <div
                  className="pan-wrapper no-left-border no-right-border"
                  style={{ width: "25%" }}
                >
                  <div className="pan-input-wrapper">
                    <PinInput
                      ref={pinRef2}
                      value={pin2}
                      name="pin2"
                      onChange={handleOnChange}
                      onKeyDown={accpetNumbersOnly}
                      pin
                      type="password"
                    />
                  </div>
                </div>
                <div
                  className="pan-wrapper no-left-border no-right-border"
                  style={{ width: "25%" }}
                >
                  <div className="pan-input-wrapper">
                    <PinInput
                      ref={pinRef3}
                      value={pin3}
                      name="pin3"
                      onChange={handleOnChange}
                      onKeyDown={accpetNumbersOnly}
                      pin
                      type="password"
                    />
                  </div>
                </div>
                <div
                  className="pan-wrapper no-left-border"
                  style={{ width: "25%" }}
                >
                  <div className="pan-input-wrapper">
                    <PinInput
                      ref={pinRef4}
                      value={pin4}
                      name="pin4"
                      onChange={handleOnChange}
                      onKeyDown={accpetNumbersOnly}
                      pin
                      type="password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      )}
      <div className="row">
        <div className="col-md-12 no-horizontal-padding">
          <Button
            disabled={shouldDisableActionButton}
            onClick={handleOnNextPress}
            style={{ padding: checkingPayment ? "0px 8px" : "10px 8px" }}
          >
            {checkingPayment ? (
              <>
                {"Continue"}
                <span>
                  <MonnifyIcons type={SPINNER_WHITE} />
                </span>
              </>
            ) : (
              <>{`Continue`}</>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CardDetails;
