import "./index.scss";

const TestBanner = () => {
  return (
    <div className="px-2 test-mode-wrapper mb-3">
      <hr />
      <div className="test-mode-container">
        <h1 className="test-mode-title"> Test </h1>
      </div>
      <hr />
    </div>
  );
};

export default TestBanner;
