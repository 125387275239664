import React, { useContext, useState } from "react";
import StoreContext from "../../services/store/store-context";
import {
  isTransactionCompleted,
  isTransactionExpired,
  isTransactionFailed,
  isTransactionPending,
  isTransactionSuccessful,
  TRANSACTION_STATUS,
} from "../../services/transaction-status.constant";
import TransactionService from "../../services/transaction.service";
import CardDetails from "./CardDetails";
import "./index.scss";
import OTP from "./OTP";
import Secure3DIpgVerification from "./secure-3d-ipg-verification";
import Secure3DVerification from "./secure-3d-verification";
import TransactionProcessing from "./TransactionProcessing";
import { STAGES as APP_STAGE } from "../../App";
import classNames from "classnames";
import constants from "../../constants";
import { PAY_WITH_OTHER_METHODS } from "../../services/constants";

export const STAGES = {
  ENTER_CARD_DETAILS: "ENTER_CARD_DETAILS",
  ENTER_OTP: "ENTER OTP",
  TRANSACTION_PROCESSING: "TRANSACTION_PROCESSING",
  SECURE_3D_AUTHORIZATION: "SECURE_3D_AUTHORIZATION",
  SECURE_3D_IPG_AUTHORIZATION: "SECURE_3D_IPG_AUTHORIZATION",
};

const PayWithCardTest = (props) => {
  const [stage, setStage] = useState(STAGES.ENTER_CARD_DETAILS);
  const context = useContext(StoreContext);
  const handleOnCardInfoEntered = (stage) => {
    switch (stage) {
      case STAGES.ENTER_OTP:
        return setStage(STAGES.ENTER_OTP);
      case STAGES.SECURE_3D_AUTHORIZATION:
        return setStage(STAGES.SECURE_3D_AUTHORIZATION);
      case STAGES.SECURE_3D_IPG_AUTHORIZATION:
        return setStage(STAGES.SECURE_3D_IPG_AUTHORIZATION);
      default:
        return setStage(STAGES.ENTER_OTP);
    }
  };
  const hanldeOTPComplete = () => {
    setStage(STAGES.TRANSACTION_PROCESSING);
  };
  const onComplete = (transactionStatus, data) => {
    context.setPaymentCompleteInfo(data);
    console.log("dataM: ", data);

    if (isTransactionFailed(transactionStatus)) {
      context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        "Transaction Failed",
        null,
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    } else if (isTransactionPending(transactionStatus)) {
      context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        "Transaction Pending",
        null,
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    } else if (isTransactionExpired(transactionStatus)) {
      context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        "Transaction Expired",
        null,
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    } else if (isTransactionSuccessful(transactionStatus)) {
      context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
    } else if (isTransactionCompleted(transactionStatus)) {
      context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        "Transaction already completed",
        null,
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    }
  };
  const checkPaymentStatus = (onFailure) => {
    TransactionService.queryTransactionStatus(
      context?.paymentInfo?.paymentData?.transactionReference,
      context?.paymentInfo?.paymentData?.apiKey,
      context?.paymentInfo?.configData?.apiUrl
    )
      .then((response) => {
        let responseData = (response && response.data) || {};
        if (
          !responseData ||
          !responseData.requestSuccessful ||
          !responseData.responseBody
        ) {
          //todo: handle error confirmation;
          //   onFailure && onFailure();
          //   this.onFlowComplete(TRANSACTION_STATUS.PENDING, {});
          return;
        }

        let responseBody = responseData.responseBody;
        let transactionStatus =
          responseBody.paymentStatus || TRANSACTION_STATUS.EXPIRED;

        if (
          transactionStatus === TRANSACTION_STATUS.PENDING ||
          transactionStatus === TRANSACTION_STATUS.PARTIALLY_PAID
        ) {
          return onComplete(transactionStatus, responseBody);
        }
        // this.onFlowComplete(transactionStatus, responseBody);

        onComplete(transactionStatus, responseBody);
      })
      .catch((error) => {
        let errorData = (error && error.response && error.response.data) || {};
        // let transactionStatus = errorData && errorData.paymentStatus;

        onComplete(TRANSACTION_STATUS.FAILED, errorData);
      });
  };
  const goBackOnOTP = () => {
    setStage(STAGES.ENTER_CARD_DETAILS);
  };
  const resetTransactionStage = () => {
    setStage(STAGES.ENTER_CARD_DETAILS);
  };
  const getTransactionStage = () => {
    switch (stage) {
      case STAGES.ENTER_CARD_DETAILS:
        return (
          <CardDetails
            onNextPress={handleOnCardInfoEntered}
            onComplete={onComplete}
          />
        );
      case STAGES.ENTER_OTP:
        return <OTP onNextPress={hanldeOTPComplete} goBack={goBackOnOTP} />;
      case STAGES.TRANSACTION_PROCESSING:
        return (
          <TransactionProcessing
            resetTransactionStage={resetTransactionStage}
            onPaymentMade={checkPaymentStatus}
          />
        );
      case STAGES.SECURE_3D_AUTHORIZATION:
        return <Secure3DVerification onComplete={onComplete} />;
      case STAGES.SECURE_3D_IPG_AUTHORIZATION:
        return (
          <Secure3DIpgVerification
            onComplete={onComplete}
            checkPaymentStatus={checkPaymentStatus}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className={classNames("container-fluid")}>
      <div
        className={classNames("pay-with-card-content-wrapper", props.className)}
      >
        {getTransactionStage()}
      </div>
    </div>
  );
};

export default PayWithCardTest;
