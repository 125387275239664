import React, { useCallback, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { STAGES } from "../../App";
import constants from "../../constants";
import MonnifyIcons from "../../icon";
import { ROLLER_ICON } from "../../icon/icon";
import StoreContext from "../../services/store/store-context";
import {
  isTransactionExpired,
  isTransactionFailed,
  isTransactionPending,
  isTransactionSuccessful,
} from "../../services/transaction-status.constant";
import TransactionService from "../../services/transaction.service";
import { STAGES as APP_STAGE } from "../../App";
import { PAY_WITH_OTHER_METHODS } from "../../services/constants";

const TransactionProcessing = ({
  resetTransactionStage,
  onPaymentMade,
  onComplete,
}) => {
  const context = useContext(StoreContext);
  const params = useParams();

  const hanldeOnAuthorize = useCallback(async () => {
    try {
      const response = await TransactionService.authorizeOtp(
        {
          token: context?.cardDetails?.token,
          tokenId: context?.cardDetails?.tokenId,
          transactionReference: params.id,
          apiKey: context?.paymentInfo?.paymentData?.apiKey,
          collectionChannel:
            context?.paymentInfo?.configData?.collectionChannel,
        },
        context?.paymentInfo?.configData?.apiUrl
      );

      let responseData = (response && response.data) || {};

      if (isTransactionFailed(responseData.responseBody.status)) {
        context.changeTransactionStage(
          STAGES.TRANSACTION_FAILED,
          responseData?.responseBody?.message,
          "Transaction Failed",
          [
            {
              text: "Try again with Card",
              onClickHanlder: () => {
                resetTransactionStage();
                context.changeTransactionStage(STAGES.TRANSACTION_PROCESSING);
              },
            },
            {
              text: PAY_WITH_OTHER_METHODS,
              onClickHanlder: () => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_CARD
                );
              },
            },
          ]
        );
      } else if (isTransactionExpired(responseData.responseBody.status)) {
        context.changeTransactionStage(
          STAGES.TRANSACTION_FAILED,
          responseData?.responseBody?.message,
          "Transaction Expired",
          [
            {
              text: "Try again with Card",
              onClickHanlder: () => {
                resetTransactionStage();
                context.changeTransactionStage(STAGES.TRANSACTION_PROCESSING);
              },
            },
            {
              text: PAY_WITH_OTHER_METHODS,
              onClickHanlder: () => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_CARD
                );
              },
            },
          ]
        );
      } else if (isTransactionPending(responseData.responseBody.status)) {
        context.changeTransactionStage(
          STAGES.TRANSACTION_FAILED,
          "Transaction Pending",
          [
            {
              text: "Try again with Card",
              onClickHanlder: () => {
                resetTransactionStage();
                context.changeTransactionStage(STAGES.TRANSACTION_PROCESSING);
              },
            },
            {
              text: PAY_WITH_OTHER_METHODS,
              onClickHanlder: () => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_CARD
                );
              },
            },
          ]
        );
      } else if (isTransactionSuccessful(responseData.responseBody.status)) {
        return onComplete(
          responseData.responseBody.status,
          responseData.responseBody
        );
      }
    } catch (error) {
      let errorData = (error && error.response && error.response.data) || {};

      if (isTransactionFailed(errorData?.responseBody?.status))
        return context.changeTransactionStage(
          STAGES.TRANSACTION_FAILED,
          errorData.responseMessage,
          "Transaction Failed",
          [
            {
              text: "Try again with Card",
              onClickHanlder: () => {
                resetTransactionStage();
                context.changeTransactionStage(STAGES.TRANSACTION_PROCESSING);
              },
            },
            {
              text: PAY_WITH_OTHER_METHODS,
              onClickHanlder: () => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_CARD
                );
              },
            },
          ]
        );

      if (isTransactionExpired(errorData?.responseBody?.status))
        return context.changeTransactionStage(
          STAGES.TRANSACTION_FAILED,
          errorData.responseMessage,
          "Transaction Expired",
          [
            {
              text: "Try again with Card",
              onClickHanlder: () => {
                resetTransactionStage();
                context.changeTransactionStage(STAGES.TRANSACTION_PROCESSING);
              },
            },
            {
              text: PAY_WITH_OTHER_METHODS,
              onClickHanlder: () => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_CARD
                );
              },
            },
          ]
        );

      if (isTransactionPending(errorData?.responseBody?.status))
        return context.changeTransactionStage(
          STAGES.TRANSACTION_FAILED,
          errorData.responseMessage,
          "Transaction Pending",
          [
            {
              text: "Try again with Card",
              onClickHanlder: () => {
                resetTransactionStage();
                context.changeTransactionStage(STAGES.TRANSACTION_PROCESSING);
              },
            },
            {
              text: PAY_WITH_OTHER_METHODS,
              onClickHanlder: () => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_CARD
                );
              },
            },
          ]
        );

      context.changeTransactionStage(
        STAGES.TRANSACTION_FAILED,
        errorData.responseMessage || "Unable to complete transaction",
        "Transaction Failed",
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              resetTransactionStage();
              context.changeTransactionStage(STAGES.TRANSACTION_PROCESSING);
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    }
  }, []);
  useEffect(() => {
    hanldeOnAuthorize();
  }, [hanldeOnAuthorize]);

  return (
    <div className="loading-wrapper">
      <div className="loading-container ">
        <div className="center-loader">
          <MonnifyIcons type={ROLLER_ICON} style={{}} />
        </div>
      </div>
    </div>
  );
};

export default TransactionProcessing;
