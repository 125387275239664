import classNames from "classnames";
import React, { useState } from "react";
// import StoreContext from '../../services/store/store-context';
import "./index.scss";
import Loading from "./Loading";
import PaymentDetails from "./PaymentDetails";
import SelectBank from "./SelectBank";
// import { STAGES } from '../../App';

const APP_STAGES = {
  SELECT_BANK: "SELECT_BANK",
  LOAD_PAYMENT_DETAILS: "LOAD_PAYMENT_DETAILS",
  PAYMENT_DETAILS_LOADED: "PAYMENT_DETAILS_LOADED",
};

const PayWithUSSD = (props) => {
  const [stage, setStage] = useState(APP_STAGES.SELECT_BANK);
  // const context = useContext(StoreContext)
  const onloaded = () => {
    setStage(APP_STAGES.PAYMENT_DETAILS_LOADED);

    /*
        
        

        */
  };

  const onBankSelected = (bank) => {
    setStage(APP_STAGES.LOAD_PAYMENT_DETAILS);
  };
  const changeBank = () => {
    setStage(APP_STAGES.SELECT_BANK);
  };
  const getTransactionStage = () => {
    switch (stage) {
      case APP_STAGES.SELECT_BANK:
        return <SelectBank onBankSelected={onBankSelected} />;
      case APP_STAGES.LOAD_PAYMENT_DETAILS:
        return <Loading onLoaded={onloaded} />;
      case APP_STAGES.PAYMENT_DETAILS_LOADED:
        return <PaymentDetails changeBank={changeBank} />;
      default:
        return <SelectBank />;
    }
  };
  return (
    <div className={classNames("container-fluid", props.className)}>
      {getTransactionStage()}
    </div>
  );
};

export default PayWithUSSD;
