import * as React from "react";
import "./style.scss"

class Loader extends React.Component {
    render() {
        return (
            <div className="monnify-loader">
                <div className="spinner-cont">
                    <div className="spinner"></div>
                </div>

                <div className="loader-text">{this.props.text}</div>
            </div>
        );
    }
}

export default Loader;