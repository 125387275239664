import React from "react";
import MonnifyIcons from "../../icon";
import { Button } from "../../styled-components/Input";

const CaptureNQR = ({ onPaymentComplete }) => {
  const hanldePaymentComplete = () => {
    onPaymentComplete();
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12 no-horizontal-padding">
          <h3 className="text-center sub-title text-black">
            Scan the QR code below with your Mobile Banking app to complete the
            payment
          </h3>
        </div>
      </div>
      <div className="py-2"></div>
      <div className="row">
        <div className="col-md-12 no-horizontal-padding">
          <div className="center-children">
            <MonnifyIcons type="NQR_IMAGE" />
          </div>
        </div>
      </div>
      <div className="py-3"></div>

      <Button onClick={hanldePaymentComplete}>
        {`I’ve completed this payment`}
      </Button>
    </>
  );
};

export default CaptureNQR;
