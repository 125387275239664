import React from "react";
import styled from "styled-components";
import "./index.scss";

const SelectInput = ({ placeholder, options, onSelect, value, disabled }) => {
  return (
    <div className="select-wrapper">
      <Select
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        onChange={onSelect}
      >
        <option value="">{placeholder || "Choose One"}</option>
        {options?.map((item, i) => (
          <option key={i} value={item.value}>
            {item.label}
          </option>
        ))}
      </Select>
      <label>Select Bank</label>
    </div>
  );
};

export const Select = styled.select`
  border: none;
  width: 100%;
  font-family: "Oxygen", "Helvetica Neue", sans-serif;
  text-align: ${(props) => (props.pin ? "center" : "left")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fff"};
  font-size: 14px;
  position: relative;
  &:focus {
    outline: 0;
  }
  &::placeholder {
    color: #d3d3d3;
    font-size: 12px;
  }
`;

export default SelectInput;
