import React, { useEffect } from "react";
import MonnifyIcons from "../../icon";

const Loading = ({onLoaded}) => {
    useEffect(() => {
       const id = setTimeout(() => {
            onLoaded()
        }, 3000)

        return () => clearInterval(id)
    },[onLoaded])

    return <div className="loading-wrapper">
        <div className="loading-container">
            <MonnifyIcons type="ROLLER_ICON" style={{}} />
        </div>
    </div>
}

export default Loading;