import React, { useState } from "react";
import { STAGES } from "../../App";
import { MESSAGE_TYPE, PAY_WITH_OTHER_METHODS } from "../constants";
import { DISABLED_PAYMENT_METHODS } from "../transaction-status.constant";
import TransactionService from "../transaction.service";
import StoreContext from "./store-context";

const StoreProvider = ({ children }) => {
  const [stage, setState] = useState(STAGES.TRANSACTION_PROCESSING);
  const [fallBackOptions, setFallBackOptions] = useState([]);
  const [paymentData, setPaymentData] = useState(null);
  const [activeLink, setActiveLink] = useState(null);
  const [cardDetails, setCardDetails] = useState({});
  const [stageMessage, setStageMessage] = useState(null);
  const [messageTitle, setMessageTitle] = useState(null);
  const [isPaid, setPaid] = useState(false);
  const [isTestMode, setTestMode] = useState(false);
  const [payloadFromHtmlInit, setPayloadFromHtmlInit] = useState({});
  const [paymentCompleteInfo, setPaymentResultInfo] = useState({});
  const changeTransactionStage = (newStage, message, title, options) => {
    if (newStage === STAGES.TRANSACTION_SUCCESSFUL) {
      TransactionService.cancelRequestOnCompleted();
      setPaid(true);
    }
    if (newStage === STAGES.TRANSACTION_FAILED) {
    }
    setState(newStage);
    setStageMessage(message);
    setMessageTitle(title);
    const menuOptions = options || [];
    const menuFallbackActions =
      getEnabledPaymentMethods().length === 1
        ? menuOptions.filter((item) => item.text !== PAY_WITH_OTHER_METHODS)
        : menuOptions;
    setFallBackOptions(menuFallbackActions);
  };
  const addFallBackOptions = (options) => {
    setFallBackOptions([...options]);
  };
  const setPaymentCompleteInfo = (data) => {
    setPaymentResultInfo(data || {});
  };

  const getEnabledPaymentMethods = () => {
    return paymentData?.paymentData?.enabledPaymentMethods || [];
  };
  const postMessage = (message) => {
    if (
      message.type === MESSAGE_TYPE.CLOSE ||
      message.type === MESSAGE_TYPE.CANCEL
    ) {
      if (stage === STAGES.TRANSACTION_SUCCESSFUL) {
        // post message to listeners

        return window.parent.postMessage(
          {
            type: MESSAGE_TYPE.TRANSACTION_SUCCESS,
            data: paymentCompleteInfo,
          },
          "*"
        );
      } else if (stage === STAGES.TRANSACTION_FAILED) {
        // post message to listeners

        return window.parent.postMessage(
          {
            type: MESSAGE_TYPE.TRANSACTION_FAILED,
            data: {
              paymentReference: paymentData.paymentData.transactionReference,
              status: "FAILED",
              payload: paymentData.paymentData,
            },
          },
          "*"
        );
      } else if (stage === STAGES.LOADING_FAILED) {
        // post message to listeners

        return window.parent.postMessage(
          {
            type: MESSAGE_TYPE.TRANSACTION_FAILED,
            data: paymentCompleteInfo,
          },
          "*"
        );
      }
    }

    window.parent.postMessage(message, "*");
  };
  const updateActiveLink = (link) => {
    setActiveLink(link);
    if (paymentData && paymentData.paymentData) {
      setPaymentData({
        ...paymentData,
        paymentData: {
          ...paymentData.paymentData,
        },
      });
    }
  };

  const getCardDetails = () => {
    return cardDetails;
  };

  const updatePayloadFromHtmlInit = (data) => {
    setPayloadFromHtmlInit(data);
  };

  const updatePaymentData = (data) => {
    if (!paymentData) return safeUpdatePaymentConfigData(data);

    return setPaymentData((state) => ({ ...state, ...data }));
  };

  const updateTestMode = (data) => {
    setTestMode(data);
  };
  const safeUpdatePaymentConfigData = (data) => {
    if (!data) return;

    const paymentMethods = data?.paymentData?.enabledPaymentMethods;

    const enabledPaymentMethods = filterPaymentMethods(paymentMethods);
    data.paymentData.enabledPaymentMethods = enabledPaymentMethods;
    setPaymentData({
      ...data,
    });
  };
  const filterPaymentMethods = (paymentOptions) => {
    return paymentOptions?.filter(
      (item) => !DISABLED_PAYMENT_METHODS.includes(item)
    );
  };
  const updateCardDetails = (data) => {
    setCardDetails((state) => ({ ...state, ...data }));
  };

  const getRedirectUrl = () => paymentData?.paymentData?.redirectUrl || null;

  const getCloseInterval = () =>
    paymentData?.configData?.merchantNotificationInterval || 3000;

  const getAPIKey = () => paymentData?.paymentData?.apiKey;

  const getAppURL = () => paymentData?.configData?.apiUrl;

  const getPaymentReference = () => paymentData.paymentInfo.paymentReference;

  return (
    <StoreContext.Provider
      value={{
        stage,
        fallBackOptions,
        paymentInfo: paymentData,
        addFallBackOptions,
        changeTransactionStage,
        postMessage,
        updatePaymentData,
        updateActiveLink,
        activeLink,
        setPaymentCompleteInfo,
        paymentCompleteInfo,
        updateCardDetails,
        getPaymentReference,
        getAPIKey,
        getAppURL,
        cardDetails,
        messageTitle,
        stageMessage,
        isPaid,
        updateTestMode,
        isTestMode,
        updatePayloadFromHtmlInit,
        payloadFromHtmlInit,
        getRedirectUrl,
        getCloseInterval,
        getCardDetails,
        getEnabledPaymentMethods,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
