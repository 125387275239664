import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  formastAsExpiryCardNumber,
  formatAsCardNumber,
  removeSlashCharacter,
  removeSpaces,
} from "../../services/utils.service";
import { Button, PinInput, TextInput } from "../../styled-components/Input";
import CardValidator from "card-validator";
import TransactionService from "../../services/transaction.service";
import StoreContext from "../../services/store/store-context";
import { useParams } from "react-router-dom";
import {
  isOtpAuthorizationRequired,
  isBankAuthorizationRequired,
  RESPONSE_CODE,
  TRANSACTION_STATUS,
  isTransactionSuccessful,
} from "../../services/transaction-status.constant";
import MonnifyIcons from "../../icon";
import { ASK_ICON, SPINNER_WHITE } from "../../icon/icon";
import { STAGES as PAY_WITH_CARD_STAGE } from ".";
import { STAGES as APP_STAGE } from "../../App";
import { Banner } from "../transfer";
import Fade from "react-reveal/Fade";
import { ERROR_BANNER, PAY_WITH_OTHER_METHODS } from "../../services/constants";
import constants from "../../constants";

CardValidator.creditCardType.addCard({
  niceType: "Verve Card",
  type: "verve",
  patterns: [
    [506099, 506198],
    [650002, 650027],
  ],
  gaps: [4, 8, 12, 16],
  lengths: [19, 16],
  code: {
    name: "CVV",
    size: 3,
  },
});

CardValidator.creditCardType.changeOrder("verve", 0);

const TEST_CARDS = [
  {
    cardNumber: "4111111111111111",
    expYear: "23",
    expMonth: "09",
    cvv: "123",
    requireOTP: false,
    type: "Success",
    pin: "1234",
  },
  {
    cardNumber: "5200000000000007",
    expYear: "22",
    expMonth: "12",
    cvv: "123",
    requireOTP: true,
    type: "Failure",
  },
];

const CardDetails = ({ onNextPress, onComplete }) => {
  const context = useContext(StoreContext);
  const params = useParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");

  const pinRef1 = useRef();
  const pinRef2 = useRef();
  const pinRef3 = useRef();
  const pinRef4 = useRef();

  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCVV] = useState("");
  const [pin, setPin] = useState("");

  const [checkingPayment, setCheckPayment] = useState(false);

  const [formComplete, setFormComplete] = useState(true);

  const [Validation, setValidation] = useState({});

  const isCardNumberValid = useCallback(() => {
    return cardNumber.replace(/\s/g, "").length >= 16;
  }, [cardNumber]);

  const isExpiryDateValid = useCallback(() => {
    return expiryDate.trim() !== "";
  }, [expiryDate]);
  const isCvvValid = useCallback(() => {
    return cvv.trim().length === 3;
  }, [cvv]);
  const isPinValid = useCallback(() => {
    return pin.trim().length === 4;
  }, [pin]);
  const evaluateCardType = useCallback(
    (callback) => {
      if (!callback) {
        callback = () => {};
      }

      let detectedCardType = CardValidator.number(removeSpaces(cardNumber));
      detectedCardType = detectedCardType || {};
      detectedCardType.card = detectedCardType.card || {};

      let maxLength = Math.max(detectedCardType.card.lengths) || 19;
      if (maxLength === 16) {
        detectedCardType.maxLength = 16;
        detectedCardType.maxLengthWithPadding = 19;
      } else if (maxLength === 19) {
        detectedCardType.maxLength = 19;
        detectedCardType.maxLengthWithPadding = 23;
      }
      // this.setState({Validation: detectedCardType}, callback);

      setValidation(detectedCardType);
    },
    [setValidation, cardNumber]
  );
  const handleOnChange = (e) => {
    const {
      target: { name, value },
    } = e;

    if (name === "cardNumber") {
      let stripedValue = removeSpaces(value);
      let newValue = formatAsCardNumber(stripedValue);

      if (
        removeSpaces(cardNumber).length === Validation?.maxLength &&
        stripedValue.length >= Validation?.maxLength
      )
        return;
      setCardNumber(newValue ? newValue : "");
    } else if (name === "expiryDate") {
      const stripedValue = removeSlashCharacter(value);
      let newValue = formastAsExpiryCardNumber(stripedValue);
      if (
        removeSlashCharacter(expiryDate).length === 4 &&
        stripedValue.length >= 4
      )
        return;
      setExpiryDate(newValue ? newValue : "");
    } else if (name === "cvv") {
      if (cvv?.trim().length === 3 && value.length >= 3) return;
      setCVV(value);
    } else if (name === "pin1") {
      if (pin1?.trim().length === 1 && value.length >= 1) {
        return pinRef2.current?.focus();
      } else if (value.trim() === "") {
        setPin1(value);
      } else {
        setPin1(value);
        return pinRef2.current?.focus();
      }
    } else if (name === "pin2") {
      if (pin2?.trim().length === 1 && value.length >= 1) {
        return pinRef3.current?.focus();
      } else {
        setPin2(value);

        return value.trim() !== ""
          ? pinRef3.current?.focus()
          : pinRef1.current?.focus();
      }
    } else if (name === "pin3") {
      if (pin3?.trim().length === 1 && value.length >= 1) {
        return pinRef4.current?.focus();
      } else {
        setPin3(value);

        return value.trim() !== ""
          ? pinRef4.current?.focus()
          : pinRef2.current?.focus();
      }
    } else if (name === "pin4") {
      if (pin4?.trim().length === 1 && value.length >= 1) {
        return;
      } else {
        setPin4(value);

        return value.trim() !== "" ? null : pinRef3.current?.focus();
      }
    }
  };
  const accpetNumbersOnly = (e) => {
    const ARROW_KEYS = [37, 38, 39, 40];
    if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      e.keyCode === 8 ||
      ARROW_KEYS.includes(e.keyCode)
    ) {
      // 0-9 only
      return;
    }
    e.preventDefault();
  };

  const isInfoComplete = useCallback(() => {
    if (
      isCardNumberValid() &&
      isExpiryDateValid() &&
      isCvvValid() &&
      isPinValid()
    ) {
      setFormComplete(false);
    } else {
      setFormComplete(true);
    }
  }, [isCardNumberValid, isCvvValid, isExpiryDateValid, isPinValid]);

  const pinChange = useCallback(() => {
    setPin(`${pin1}${pin2}${pin3}${pin4}`);
    isInfoComplete();
  }, [pin1, pin2, pin3, pin4, isInfoComplete]);

  useEffect(() => {
    isInfoComplete();
  }, [cardNumber, cvv, expiryDate, pin, isInfoComplete]);

  useEffect(() => {
    pinChange();
  }, [pin1, pin2, pin3, pin4, pinChange]);

  useEffect(() => {
    evaluateCardType();
  }, [evaluateCardType, cardNumber]);

  const [isPinRequired, setPinRequired] = useState(false);

  const getCardDetails = useCallback(() => {
    if (removeSpaces(cardNumber).length >= 16) {
      TransactionService.getCardRequirements({
        cardNumber: removeSpaces(cardNumber),
        collectionChannel: context?.paymentInfo?.configData?.collectionChannel,
        transactionReference: params.id,
        domain: context?.paymentInfo?.configData?.apiUrl,
      })
        .then((response) => {
          let cardRequirement = { requirePin: true };
          let responseData = (response && response.data) || {};

          if (
            responseData &&
            responseData.requestSuccessful &&
            responseData.responseBody
          ) {
            cardRequirement = responseData.responseBody || { requirePin: true };
          }

          setPinRequired(cardRequirement.requirePin);
        })
        .catch((error) => {
          setPinRequired(false);
        });
    }
    return setPinRequired(false);
  }, [
    setPinRequired,
    cardNumber,
    params.id,
    context?.paymentInfo?.configData?.apiUrl,
    context?.paymentInfo?.configData?.collectionChannel,
  ]);

  useEffect(() => {
    getCardDetails();
  }, [getCardDetails]);

  const handleOnNextPress = async () => {
    try {
      if (checkingPayment) return;

      const dateParts = expiryDate.split("/");
      const cardInfo = {
        number: removeSpaces(cardNumber),
        cvv,
        expiryMonth: dateParts[0],
        expiryYear: `20${dateParts[dateParts.length - 1]}`,
        pin: `${pin1}${pin2}${pin3}${pin4}`,
      };

      setCheckPayment(true);
      setErrorMessage(null);

      const response = await TransactionService.chargeCard(
        {
          transactionReference: params.id,
          apiKey: context?.paymentInfo?.configData?.apiKey,
          collectionChannel:
            context?.paymentInfo?.configData?.collectionChannel,
          card: cardInfo,
        },
        context?.paymentInfo?.configData.apiUrl
      );

      console.log("response>", response.data);

      setCheckPayment(false);

      const responseData = (response && response.data) || {};

      if (
        !responseData ||
        !responseData.requestSuccessful ||
        !responseData.responseBody
      ) {
        // self.onComplete(
        //   TRANSACTION_STATUS.FAILED,
        //   responseData.responseBody
        // );
        return;
      }

      let paymentStatus = responseData.responseBody.status;
      const selectedCard = TEST_CARDS.find(
        (item) => item.cardNumber === cardInfo.number
      );
      if (!selectedCard?.requireOTP && isTransactionSuccessful(paymentStatus)) {
        onComplete(paymentStatus, responseData.responseBody);
      }

      const tokenId = responseData?.responseBody?.otpData?.id;

      const secure3dData = responseData?.responseBody?.secure3dData;

      context.updateCardDetails({ ...cardInfo, tokenId, secure3dData });

      if (responseData?.responseBody?.status === TRANSACTION_STATUS.FAILED) {
        return context.changeTransactionStage(
          APP_STAGE.TRANSACTION_FAILED,
          responseData?.responseBody?.message,
          null,
          [
            {
              text: "Try again with Card",
              onClickHanlder: () => {
                context.changeTransactionStage(
                  APP_STAGE.TRANSACTION_PROCESSING
                );
              },
            },
            {
              text: PAY_WITH_OTHER_METHODS,
              onClickHanlder: () => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_CARD
                );
              },
            },
          ]
        );
      }

      if (isOtpAuthorizationRequired(paymentStatus)) {
        return onNextPress();
      }

      if (isBankAuthorizationRequired(paymentStatus)) {
        // self.gotoBankVerification(responseData.responseBody.secure3dData);
        if (
          secure3dData.termUrl &&
          secure3dData.termUrl.indexOf("interswitchng") >= 0
        ) {
          return onNextPress(PAY_WITH_CARD_STAGE.SECURE_3D_IPG_AUTHORIZATION);
        }
        return onNextPress(PAY_WITH_CARD_STAGE.SECURE_3D_AUTHORIZATION);
      }
    } catch (error) {
      setCheckPayment(false);

      let errorData = (error && error.response && error.response.data) || {};
      let responseCode = errorData.responseCode;

      setErrorMessage(errorData.responseMessage);
      let transactionStatus = errorData && errorData.status;

      if (responseCode === RESPONSE_CODE.TRANSACTION_COMPLETED) {
        // self.onComplete(TRANSACTION_STATUS.FAILED, errorData);
        return;
      }

      onComplete(transactionStatus, errorData);
    }
  };

  const getCardType = () => {
    return Validation?.card?.type;
  };

  const handleOnTestCardSelect = (e) => {
    const value = e.target.value;

    if (value.trim() !== "") {
      const card = TEST_CARDS.find(
        (item) =>
          `${item.type} - ${item.cardNumber.substring(
            item.cardNumber.length - 4,
            item.cardNumber.length
          )}` === value
      );

      setCardNumber(formatAsCardNumber(card.cardNumber));
      setExpiryDate(
        formastAsExpiryCardNumber(`${card.expMonth}${card.expYear}`)
      );

      setCVV(card.cvv);

      setPin("1234");
      setPin1("1");
      setPin2("2");
      setPin3("3");
      setPin4("4");
    }
  };

  useEffect(() => {
    // if format === 02/01/20
    if (expiryDate.length >= 8) {
      const dateParts = expiryDate.split("/");
      const month = dateParts[0];
      const year = dateParts[dateParts.length - 1];

      setExpiryDate(`${month}/${year}`);
    }
  }, [expiryDate]);

  return (
    <>
      {errorMessage && (
        <>
          <Fade top>
            <Banner type={ERROR_BANNER} text={errorMessage} />
          </Fade>
          <div className="py-1"></div>
        </>
      )}

      <div className="row">
        <div className="col-md-12">
          <h3 className="text-center sub-title text-black">
            Select a test card below to complete your payment
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 no-horizontal-padding mb-3">
          <div className="custom-test-select-wrapper">
            <select
              onChange={handleOnTestCardSelect}
              className="custom-test-select"
            >
              <option value={""}>Select Card</option>
              {TEST_CARDS.map((item, i) => (
                <option
                  key={i}
                  value={`${item.type} - ${item.cardNumber.substring(
                    item.cardNumber.length - 4,
                    item.cardNumber.length
                  )}`}
                >
                  {`${item.type} - ${item.cardNumber.substring(
                    item.cardNumber.length - 4,
                    item.cardNumber.length
                  )}`}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-12 no-horizontal-padding">
          <div className="pan-wrapper">
            <label>Card Number</label>
            <div className="pan-input-wrapper">
              <TextInput
                onKeyDown={accpetNumbersOnly}
                value={cardNumber}
                name="cardNumber"
                onChange={handleOnChange}
                type="text"
                placeholder="0000 0000 0000 0000"
              />
            </div>
            <span className={`card-type ${getCardType()}`}></span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 no-horizontal-padding">
          <div className="d-flex">
            <div
              className="pan-wrapper no-right-border"
              style={{ width: "50%" }}
            >
              <label>Expiry Date</label>
              <div className="pan-input-wrapper">
                <TextInput
                  onKeyDown={accpetNumbersOnly}
                  value={expiryDate}
                  name="expiryDate"
                  onChange={handleOnChange}
                  placeholder="01/12"
                  type="text"
                />
              </div>
            </div>
            <div
              className="pan-wrapper no-left-border"
              style={{ width: "50%" }}
            >
              <label>CVV</label>
              <div className="pan-input-wrapper">
                <TextInput
                  onKeyDown={accpetNumbersOnly}
                  value={cvv}
                  name="cvv"
                  onChange={handleOnChange}
                  type="text"
                  placeholder="000"
                />
              </div>
              <span className={`card-type cursor-pointer`}>
                <span className="custom-tooltip">
                  <MonnifyIcons type={ASK_ICON} />
                  <span className="tooltiptext"></span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      {isPinRequired && (
        <Fade bottom>
          <div className="py-2"></div>
          <h3 className="text-grey  text-black text-center">
            Enter your Card PIN
          </h3>
          <div className="row">
            <div className="col-md-12 no-horizontal-padding">
              <div className="d-flex">
                <div
                  className="pan-wrapper no-right-border"
                  style={{ width: "25%" }}
                >
                  <div className="pan-input-wrapper">
                    <PinInput
                      ref={pinRef1}
                      value={pin1}
                      name="pin1"
                      onChange={handleOnChange}
                      onKeyDown={accpetNumbersOnly}
                      pin
                      type="password"
                    />
                  </div>
                </div>
                <div
                  className="pan-wrapper no-left-border"
                  style={{ width: "25%" }}
                >
                  <div className="pan-input-wrapper">
                    <PinInput
                      ref={pinRef2}
                      value={pin2}
                      name="pin2"
                      onChange={handleOnChange}
                      onKeyDown={accpetNumbersOnly}
                      pin
                      type="password"
                    />
                  </div>
                </div>
                <div
                  className="pan-wrapper no-right-border"
                  style={{ width: "25%" }}
                >
                  <div className="pan-input-wrapper">
                    <PinInput
                      ref={pinRef3}
                      value={pin3}
                      name="pin3"
                      onChange={handleOnChange}
                      onKeyDown={accpetNumbersOnly}
                      pin
                      type="password"
                    />
                  </div>
                </div>
                <div
                  className="pan-wrapper no-left-border"
                  style={{ width: "25%" }}
                >
                  <div className="pan-input-wrapper">
                    <PinInput
                      ref={pinRef4}
                      value={pin4}
                      name="pin4"
                      onChange={handleOnChange}
                      onKeyDown={accpetNumbersOnly}
                      pin
                      type="password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      )}
      <div className="row">
        <div className="col-md-12 no-horizontal-padding">
          <Button
            disabled={formComplete}
            onClick={handleOnNextPress}
            style={{ padding: checkingPayment ? "0px 8px" : "10px 8px" }}
          >
            {checkingPayment ? (
              <>
                {"Continue"}
                <span>
                  <MonnifyIcons type={SPINNER_WHITE} />
                </span>
              </>
            ) : (
              <>{`Continue`}</>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CardDetails;
