import React, { useState } from "react";
import EnterAccountNumber from "./EnterAccountNumber";
import EnterOTP from "./EnterOTP";
// import StoreContext from '../../services/store/store-context';
import "./index.scss";
import Loading from "./Loading";
import SelectBank from "./SelectBank";
// import { STAGES } from '../../App';

const APP_STAGES = {
  SELECT_BANK: "SELECT_BANK",
  LOAD_PAYMENT_DETAILS: "LOAD_PAYMENT_DETAILS",
  ENTER_ACCOUNT_NUMBER: "ENTER_ACCOUNT_NUMBER",
  ENTER_OTP: "ENTER_OTP",
};

const PayWithBank = () => {
  const [stage, setStage] = useState(APP_STAGES.SELECT_BANK);
  // const context = useContext(StoreContext)
  const onloaded = () => {
    setStage(APP_STAGES.ENTER_ACCOUNT_NUMBER);
  };

  const onBankSelected = (bank) => {
    setStage(APP_STAGES.LOAD_PAYMENT_DETAILS);
  };

  const resetStage = () => {
    setStage(APP_STAGES.SELECT_BANK);
  };

  const onAccountNameValidated = () => {
    setStage(APP_STAGES.ENTER_OTP);
  };

  const getTransactionStage = () => {
    switch (stage) {
      case APP_STAGES.SELECT_BANK:
        return <SelectBank onBankSelected={onBankSelected} />;
      case APP_STAGES.LOAD_PAYMENT_DETAILS:
        return <Loading onLoaded={onloaded} />;
      case APP_STAGES.ENTER_ACCOUNT_NUMBER:
        return (
          <EnterAccountNumber
            resetStage={resetStage}
            onContinue={onAccountNameValidated}
          />
        );
      case APP_STAGES.ENTER_OTP:
        return <EnterOTP resetStage={resetStage} />;
      default:
        return <SelectBank />;
    }
  };
  return <div className="container-fluid">{getTransactionStage()}</div>;
};

export default PayWithBank;
