import React, { useState, useContext } from "react";
import CaptureNQR from "./CaptureNQR";
import "./index.scss";
import Loading from "./Loading";

import StoreContext from "../../services/store/store-context";
import { STAGES } from "../../App";
import classNames from "classnames";
import { PAY_WITH_OTHER_METHODS } from "../../services/constants";

const APP_STAGES = {
  INIT_STAGE: "INIT_STAGE",
  LOADING_STAGE: "LOADING_STAGE",
};

const PayWithNQR = (props) => {
  const context = useContext(StoreContext);
  const [stage, setStage] = useState(APP_STAGES.SELECT_BANK);

  const onloaded = () => {
    context.changeTransactionStage(STAGES.TRANSACTION_FAILED);
    context.addFallBackOptions([
      {
        onClickHandler: () => {
          context.changeTransactionStage(STAGES.TRANSACTION_PROCESSING);
        },
        text: "Try again with NQR",
      },
      {
        onClickHandler: () => {
          context.changeTransactionStage(STAGES.TRANSACTION_PROCESSING);
        },
        text: PAY_WITH_OTHER_METHODS,
      },
    ]);
  };

  const onPaymentComplete = () => {
    setStage(APP_STAGES.LOADING_STAGE);
  };

  const getTransactionStage = () => {
    switch (stage) {
      case APP_STAGES.INIT_STAGE:
        return <CaptureNQR onPaymentComplete={onPaymentComplete} />;
      case APP_STAGES.LOADING_STAGE:
        return <Loading onLoaded={onloaded} />;

      default:
        return <CaptureNQR onPaymentComplete={onPaymentComplete} />;
    }
  };
  return (
    <div className={classNames("container-fluid", props.className)}>
      {getTransactionStage()}
    </div>
  );
};

export default PayWithNQR;
