import React, { useState, useEffect, useContext } from "react";
import constants from "../../constants";
import StoreContext from "../../services/store/store-context";
import Menu from "../menu";
import Monnify from "../monnify";
import "./index.scss";
import MobileMenuList from "./MobileMenuList";

const Content = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(true);
  const context = useContext(StoreContext);
  const smScreenHanlder = (e) => {
    if (e.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    setIsSmallScreen(window.matchMedia("(max-width: 768px)").matches);
  }, []);

  useEffect(() => {
    window
      .matchMedia("(max-width: 768px)")
      .addEventListener("change", smScreenHanlder);
    return window
      .matchMedia("(max-width: 768px)")
      .removeEventListener("change", smScreenHanlder);
  }, []);

  const [activeLink, setActiveLink] = useState(
    constants.PAY_WITH_ACCOUNT_TRANSFER
  );
  const onActiveLinkChange = (listItem) => {
    setActiveLink(listItem);
    context.updateActiveLink(listItem);
  };

  const showContent = () => {
    if (!isSmallScreen) {
      return (
        <div className="container-fluid app-wrapper-content">
          <div className="row">
            <div className="col-md-4 col-sm-4 col-lg-4 no-horizontal-padding">
              <div className="row py-3">
                <div className="col-md-12 no-horizontal-padding">
                  <h3 className="title pl-2 text-grey pay-with-title">
                    PAY WITH
                  </h3>
                </div>
              </div>
              <Menu onActiveLinkChange={onActiveLinkChange} />
            </div>
            <div
              style={{ paddingRight: 0 }}
              className="col-md-8 col-sm-8 col-lg-8 no-horizontal-padding"
            >
              <Monnify activeLink={activeLink} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12 no-horizontal-padding">
            <MobileMenuList />
          </div>
        </div>
      </div>
    );
  };
  return showContent();
};

export default Content;
