import React, { useEffect, useState } from "react";
import MonnifyIcons from "../../icon";
import { SPINNER_WHITE } from "../../icon/icon";
import { ERROR_BANNER } from "../../services/constants";
import { Button, TextInput } from "../../styled-components/Input";
import { Banner } from "../transfer";
import Fade from "react-reveal/Fade";

const EnterPhoneNUmber = ({
  onPhoneNumberComplete,
  message,
  phoneNumber: previousPhoneNumber,
  clearMessage,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(previousPhoneNumber);
  const [isDisabled, setIsDisabled] = useState(true);
  const [checkingPayment] = useState(false);

  const handleOnChange = (e) => {
    clearMessage();
    setPhoneNumber(e.target.value);
  };

  useEffect(() => {
    if (phoneNumber.length >= 11) {
      setIsDisabled(false);
    }
  }, [phoneNumber]);

  const handleOnConfirm = () => {
    onPhoneNumberComplete(phoneNumber);
  };
  const isCopyOrPasteAction = (e) => {
    return (
      (e.ctrlKey || e.metaKey) &&
      (e.code === "KeyC" || e.code === "KeyV" || e.code === 'code: "KeyX"')
    );
  };
  const accpetNumbersOnly = (e) => {
    const ARROW_KEYS = [37, 38, 39, 40];
    const BACK_SPACE = 8;

    // 48 - 57 keycode for numbers
    if (isCopyOrPasteAction(e)) return;
    else if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      e.keyCode === BACK_SPACE ||
      ARROW_KEYS.includes(e.keyCode)
    ) {
      // 0-9 only
      return;
    }
    e.preventDefault();
  };
  return (
    <>
      {message && (
        <>
          <Fade top>
            <Banner type={ERROR_BANNER} text={message} />
          </Fade>
          <div className="py-1"></div>
        </>
      )}
      <div className="row mb-2">
        <div className="col-md-12 no-horizontal-padding">
          <h3 className="text-left sub-title text-black">
            Enter your phone number to begin payment
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 no-horizontal-padding">
          <div className="pan-wrapper">
            <label>Enter phone number (ex 080xxxxxxxx)</label>
            <div className="pan-input-wrapper">
              <TextInput
                value={phoneNumber}
                name="phoneNumber"
                onChange={handleOnChange}
                placeholder="08022233344"
                type="text"
                onKeyDown={accpetNumbersOnly}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="py-2"></div>
      <div className="row">
        <div className="col-md-12 no-horizontal-padding">
          <Button
            disabled={isDisabled}
            onClick={handleOnConfirm}
            style={{ padding: checkingPayment ? "0px 8px" : "10px 8px" }}
          >
            {checkingPayment ? (
              <>
                {"Continue"}
                <span>
                  <MonnifyIcons type={SPINNER_WHITE} />
                </span>
              </>
            ) : (
              <>{`Continue`}</>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default EnterPhoneNUmber;
