import React, { useContext, useEffect, useMemo, useState } from "react";
import MonnifyIcons from "../../icon";
import MobileMenuSelect from "./MobileMenuSelect";
import constants from "../../constants";
import StoreContext from "../../services/store/store-context";
import Header from "../header/index";
import {
  BANK_ICON,
  CARD_ICON,
  CASH_ICON,
  NQR_ICON,
  PHONE_ICON,
  TRANSFER_ICON,
  USSD_ICON,
} from "../../icon/icon";
import { STAGES } from "../../App";
import Failed from "../../page/transaction-status/Failed";
import Success from "../../page/transaction-status/Success";
import classNames from "classnames";
import { PAYMENT_METHODS } from "../../services/constants";
import { mapPaymentMethodFromServerToApplication } from "../../services/transaction-status.constant";

const MobileMenuList = () => {
  const context = useContext(StoreContext);

  const [activeLink, setActiveLink] = useState(null);

  const [previousLink, setPreviousLink] = useState(null);

  const onListItemPress = (listItem) => {
    if (context.stage !== STAGES.TRANSACTION_PROCESSING) return;
    setActiveLink(listItem);
    context.updateActiveLink(listItem);
  };
  const getClass = (listItem) => {
    return activeLink === listItem ? "active" : "";
  };

  const onPaymentMethodChange = () => {
    setActiveLink(null);
    setPreviousLink(activeLink);
  };
  const renderActiveLink = () => {
    switch (previousLink) {
      case constants.PAY_WITH_CARD:
        return (
          <MobileMenuListItem
            name={"Pay with Card"}
            iconName={CARD_ICON}
            link={constants.PAY_WITH_CARD}
            previousPage={previousLink}
            activeLink={activeLink}
            activeLinkClass="mobile-menu-link-active"
            onListItemPress={onListItemPress}
            listClassName={getClass(constants.PAY_WITH_CARD)}
          />
        );
      case constants.PAY_WITH_ACCOUNT_TRANSFER:
        return (
          <MobileMenuListItem
            name={"Pay with Transfer"}
            iconName={TRANSFER_ICON}
            link={constants.PAY_WITH_ACCOUNT_TRANSFER}
            previousPage={previousLink}
            activeLink={activeLink}
            activeLinkClass="mobile-menu-link-active"
            onListItemPress={onListItemPress}
            listClassName={getClass(constants.PAY_WITH_ACCOUNT_TRANSFER)}
          />
        );
      case constants.PAY_WITH_BANK:
        return (
          <MobileMenuListItem
            name={"Pay with Bank"}
            iconName={TRANSFER_ICON}
            link={constants.PAY_WITH_BANK}
            previousPage={previousLink}
            activeLink={activeLink}
            activeLinkClass="mobile-menu-link-active"
            onListItemPress={onListItemPress}
            listClassName={getClass(constants.PAY_WITH_ACCOUNT_TRANSFER)}
          />
        );
      case constants.PAY_WITH_USSD:
        return (
          <MobileMenuListItem
            name={"Pay with USSD"}
            iconName={USSD_ICON}
            link={constants.PAY_WITH_USSD}
            previousPage={previousLink}
            activeLink={activeLink}
            activeLinkClass="mobile-menu-link-active"
            onListItemPress={onListItemPress}
            listClassName={getClass(constants.PAY_WITH_USSD)}
          />
        );
      case constants.PAY_WITH_NQR:
        return (
          <MobileMenuListItem
            name={"Pay with NQR"}
            iconName={NQR_ICON}
            link={constants.PAY_WITH_NQR}
            previousPage={previousLink}
            activeLink={activeLink}
            activeLinkClass="mobile-menu-link-active"
            onListItemPress={onListItemPress}
            listClassName={getClass(constants.PAY_WITH_NQR)}
          />
        );
      case constants.PAY_WITH_CASH_V2:
        return (
          <MobileMenuListItem
            name={"Pay with Cash"}
            iconName={CASH_ICON}
            link={constants.PAY_WITH_CASH}
            previousPage={previousLink}
            activeLink={activeLink}
            activeLinkClass="mobile-menu-link-active"
            onListItemPress={onListItemPress}
            listClassName={getClass(constants.PAY_WITH_CASH)}
          />
        );
      case constants.PAY_WITH_PHONE_NO:
        return (
          <MobileMenuListItem
            name={"Pay with Phone No."}
            iconName={PHONE_ICON}
            link={constants.PAY_WITH_PHONE_NO}
            previousPage={previousLink}
            activeLink={activeLink}
            activeLinkClass="mobile-menu-link-active"
            onListItemPress={onListItemPress}
            listClassName={getClass(constants.PAY_WITH_PHONE_NO)}
          />
        );
      default:
        return null;
    }
  };

  const enabledPaymentMethods = useMemo(
    () => context.getEnabledPaymentMethods(),
    [context?.paymentInfo?.paymentData]
  );

  useEffect(() => {
    if (enabledPaymentMethods.length > 1) return;

    onListItemPress(
      mapPaymentMethodFromServerToApplication(enabledPaymentMethods[0])
    );
  }, [enabledPaymentMethods]);

  const renderPaymentStage = () => {
    switch (context.stage) {
      case STAGES.TRANSACTION_FAILED:
        return (
          <div className="px-2">
            <Failed />
          </div>
        );
      case STAGES.TRANSACTION_SUCCESSFUL:
        return (
          <div className="px-2">
            <Success />
          </div>
        );
      default:
        return (
          <>
            <div className="row py-3">
              <div className="col-md-12">
                <h3 className="title text-upper-case text-grey">
                  Select payment method
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <ul className="mobile-menu">
                  {renderActiveLink()}
                  {!enabledPaymentMethods.includes(
                    PAYMENT_METHODS.ACCOUNT_TRANSFER
                  ) ||
                    previousLink === constants.PAY_WITH_ACCOUNT_TRANSFER || (
                      <MobileMenuListItem
                        name={"Pay with Transfer"}
                        iconName={TRANSFER_ICON}
                        link={constants.PAY_WITH_ACCOUNT_TRANSFER}
                        previousPage={constants.PAY_WITH_BANK_V2}
                        onListItemPress={onListItemPress}
                        activeLink={activeLink}
                        listClassName={getClass(
                          constants.PAY_WITH_ACCOUNT_TRANSFER
                        )}
                      />
                    )}
                  {!enabledPaymentMethods.includes(PAYMENT_METHODS.CARD) ||
                    previousLink === constants.PAY_WITH_CARD || (
                      <MobileMenuListItem
                        name={"Pay with Card"}
                        iconName={CARD_ICON}
                        link={constants.PAY_WITH_CARD}
                        previousPage={previousLink}
                        activeLink={activeLink}
                        onListItemPress={onListItemPress}
                        listClassName={getClass(constants.PAY_WITH_CARD)}
                      />
                    )}
                  {!enabledPaymentMethods.includes(PAYMENT_METHODS.USSD) ||
                    previousLink === constants.PAY_WITH_USSD || (
                      <MobileMenuListItem
                        name={"Pay with USSD"}
                        iconName={USSD_ICON}
                        link={constants.PAY_WITH_USSD}
                        previousPage={constants.PAY_WITH_USSD_V2}
                        activeLink={activeLink}
                        onListItemPress={onListItemPress}
                        listClassName={getClass(constants.PAY_WITH_USSD)}
                      />
                    )}

                  {!enabledPaymentMethods.includes(
                    PAYMENT_METHODS.PHONE_NUMBER
                  ) ||
                    previousLink === constants.PAY_WITH_PHONE_NO || (
                      <MobileMenuListItem
                        name={"Pay with Phone No."}
                        iconName={CASH_ICON}
                        link={constants.PAY_WITH_PHONE_NO}
                        previousPage={constants.PAY_WITH_PHONE_NO_V2}
                        activeLink={activeLink}
                        onListItemPress={onListItemPress}
                        listClassName={getClass(constants.PAY_WITH_PHONE_NO)}
                      />
                    )}

                  {!enabledPaymentMethods.includes(
                    PAYMENT_METHODS.DIRECT_DEBIT
                  ) ||
                    previousLink === constants.PAY_WITH_BANK || (
                      <MobileMenuListItem
                        name={"Pay with Bank"}
                        iconName={BANK_ICON}
                        link={constants.PAY_WITH_BANK}
                        previousPage={constants.PAY_WITH_BANK_V2}
                        activeLink={activeLink}
                        onListItemPress={onListItemPress}
                        listClassName={getClass(constants.PAY_WITH_BANK)}
                      />
                    )}
                </ul>
              </div>
            </div>
          </>
        );
    }
  };
  const showContent = () => {
    switch (activeLink) {
      case constants.PAY_WITH_CARD:
      case constants.PAY_WITH_ACCOUNT_TRANSFER:
      case constants.PAY_WITH_BANK:
      case constants.PAY_WITH_USSD:
      case constants.NQR:
      case constants.PAY_WITH_PHONE_NO:
        return (
          <MobileMenuSelect
            paymentMethod={activeLink}
            onPaymentMethodChange={onPaymentMethodChange}
          />
        );

      default:
        return (
          <div className="mobile-menu-wrapper">
            <div className="py-2"></div>
            <div className="px-2">
              <Header />
            </div>
            {renderPaymentStage()}
          </div>
        );
    }
  };
  return showContent();
};

const MobileMenuListItem = ({
  name,
  link,
  previousPage,
  iconName,
  onListItemPress,
  listClassName,
  activeLinkClass,
}) => {
  return (
    <li
      className={`${classNames(listClassName, activeLinkClass)}`}
      onClick={() => onListItemPress(link)}
    >
      <div className="d-flex">
        <span>
          <MonnifyIcons type={iconName} />

          <span className="sl-1">{name}</span>
        </span>
        {previousPage === link ? (
          <span className="icon-forward d-flex">
            <span className=" text-primary">Hide</span>
          </span>
        ) : (
          <span className="icon-forward">
            <MonnifyIcons type="ARROW_FORWARD_ICON" />
          </span>
        )}
      </div>
    </li>
  );
};

export default MobileMenuList;
