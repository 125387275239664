import React, { useContext } from "react";
import constants from "../../constants";
import PayWithBank from "../../page/bank";
import PayWithNQR from "../../page/nqr";
import PayWithCard from "../../page/pay-with-card";
import BankTranfer from "../../page/transfer";
import PayWithUSSD from "../../page/ussd";
import { STAGES as APP_STAGE } from "../../App";
import "./index.scss";
import Success from "../../page/transaction-status/Success";
import Failed from "../../page/transaction-status/Failed";
import StoreContext from "../../services/store/store-context";
import Header from "../header";
import PayWithPhone from "../../page/pay-with-phone";
import TestBanner from "../TestBanner";
import PayWithCardTest from "../../page/pay-with-card-test";

const Monnify = () => {
  const context = useContext(StoreContext);
  const activeLink = context.activeLink;

  const showPaymentStage = (context) => {
    switch (context.stage) {
      case APP_STAGE.TRANSACTION_PROCESSING:
        return showContent();
      case APP_STAGE.TRANSACTION_SUCCESSFUL:
        return <Success />;
      case APP_STAGE.TRANSACTION_FAILED:
        return <Failed />;
      default:
        return showContent();
    }
  };
  const showContent = () => {
    if (activeLink === constants.PAY_WITH_CARD) {
      return <>{context.isTestMode ? <PayWithCardTest /> : <PayWithCard />}</>;
    }
    if (activeLink === constants.PAY_WITH_ACCOUNT_TRANSFER) {
      return <BankTranfer />;
    }
    if (activeLink === constants.PAY_WITH_USSD) {
      return <PayWithUSSD />;
    }
    if (activeLink === constants.NQR) {
      return <PayWithNQR />;
    }
    if (activeLink === constants.PAY_WITH_BANK) {
      return <PayWithBank />;
    }
    if (activeLink === constants.PAY_WITH_PHONE_NO) {
      return <PayWithPhone />;
    }

    return null;
  };
  return (
    <>
      <div className="content-wrapper">
        <div className="px-2">
          <Header />
        </div>
        {context.isTestMode && (
          <div className="py-3">
            <TestBanner />
          </div>
        )}

        {showPaymentStage(context)}
      </div>
    </>
  );
};

export default Monnify;
