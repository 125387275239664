import { useCallback, useContext, useEffect, useState } from "react";
import MonnifyIcons from "../../icon";
import { OTP_ICON, SPINNER_WHITE } from "../../icon/icon";
import { ERROR_BANNER, PAY_WITH_OTHER_METHODS } from "../../services/constants";
import { Button, TextInput } from "../../styled-components/Input";
import { Banner } from "../transfer";
import Fade from "react-reveal/Fade";
import TransactionService from "../../services/transaction.service";
import { useParams } from "react-router-dom";
import StoreContext from "../../services/store/store-context";
import {
  isTransactionFailed,
  RESPONSE_CODE,
  TRANSACTION_STATUS,
} from "../../services/transaction-status.constant";
import { STAGES as APP_STAGE } from "../../App";
import Loader from "../../component/loader";
import constants from "../../constants";
import Axios from "axios";

let pollingIntervalID;

const EnterOTP = ({ resetStage }) => {
  const [token, setToken] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const handleOnChange = (e) => {
    setErrorMessage(null);
    setToken(e.target.value);
  };

  const [checkingPayment, setCheckingPayment] = useState(false);
  const params = useParams();
  const context = useContext(StoreContext);
  const [providerReference, setProviderReference] = useState(null);
  const [isPolling, setPolling] = useState(false);
  const [intervalID, setIntervalId] = useState(null);
  const initiateCharge = useCallback(async () => {
    try {
      const response = await TransactionService.initiateBankCharge(
        {
          transactionReference: params.id,
          bankCode: context?.paymentInfo?.paymentData?.bankUssdCode,
          accountNumber: context?.paymentInfo?.paymentData?.accountNumber,
          apiKey: context?.paymentInfo?.paymentData?.apiKey,
          collectionChannel:
            context?.paymentInfo?.configData?.collectionChannel,
        },
        context?.paymentInfo?.configData?.apiUrl
      );
      const responseBody = response?.data?.responseBody || {};
      if (responseBody.status === TRANSACTION_STATUS.PENDING) {
        return setProviderReference(responseBody.providerReference);
      } else if (isTransactionFailed(responseBody.status)) {
        context.changeTransactionStage(
          APP_STAGE.TRANSACTION_FAILED,
          "Transaction Failed",
          "Transaction Failed",
          [
            {
              text: "Try again with Bank",
              onClickHanlder: () => {
                context.changeTransactionStage(
                  APP_STAGE.TRANSACTION_PROCESSING
                );
              },
            },
            {
              text: PAY_WITH_OTHER_METHODS,
              onClickHanlder: () => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_BANK
                );
              },
            },
          ]
        );
      } else if (
        responseBody.responseDescription &&
        responseBody.responseDescription !==
          TRANSACTION_STATUS.TRANSACTION_PENINDG
      ) {
        context.changeTransactionStage(
          APP_STAGE.TRANSACTION_FAILED,
          responseBody.responseDescription,
          "Transaction Pending",
          [
            {
              text: "Try again with Bank",
              onClickHanlder: () => {
                context.changeTransactionStage(
                  APP_STAGE.TRANSACTION_PROCESSING
                );
              },
            },
            {
              text: PAY_WITH_OTHER_METHODS,
              onClickHanlder: () => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_BANK
                );
              },
            },
          ]
        );
      }
    } catch (error) {
      const errorData = (error && error.response && error.response.data) || {};
      // do something with the error
      handleError(errorData);
    }
  }, [
    params.id,
    context?.paymentInfo?.configData?.apiUrl,
    context?.paymentInfo?.paymentData?.apiKey,
    context?.paymentInfo?.configData?.collectionChannel,
    context?.paymentInfo?.paymentData?.bankUssdCode,
    context?.paymentInfo?.paymentData?.accountNumber,
  ]);

  const handleError = useCallback((data) => {
    const error =
      (data && data.responseMessage) ||
      "Payment could not be completed. Please try again or use another payment method";
    const responseCode = error.responseCode;

    if (responseCode === RESPONSE_CODE.TRANSACTION_COMPLETED) {
      context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
      return;
    }
    context.changeTransactionStage(
      APP_STAGE.TRANSACTION_FAILED,
      error,
      "Transaction Failed",
      [
        {
          text: "Try again with Bank",
          onClickHanlder: () => {
            context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
          },
        },
        {
          text: PAY_WITH_OTHER_METHODS,
          onClickHanlder: () => {
            TransactionService.switchNextPaymentMethod(
              context,
              constants.PAY_WITH_BANK
            );
          },
        },
      ]
    );
  }, []);

  useEffect(() => {
    initiateCharge();
    return () => {
      stopPolling();
    };
  }, []);
  const authorizePayment = async () => {
    try {
      setCheckingPayment(true);
      const response = await TransactionService.authorizeBankCharge(
        {
          transactionReference: params.id,
          providerReference,
          apiKey: context?.paymentInfo?.paymentData?.apiKey,
          collectionChannel:
            context?.paymentInfo?.configData?.collectionChannel,
          token,
        },
        context?.paymentInfo?.configData?.apiUrl
      );
      setCheckingPayment(false);
      const responseBody = response?.data?.responseBody || {};
      if (responseBody.status === TRANSACTION_STATUS.PENDING) {
        return startPolling();
      }
      if (responseBody.status === TRANSACTION_STATUS.FAILED) {
        context.changeTransactionStage(
          APP_STAGE.TRANSACTION_FAILED,
          "Invalid OTP",
          "Transaction Failed",
          [
            {
              text: "Try again with Bank",
              onClickHanlder: () => {
                context.changeTransactionStage(
                  APP_STAGE.TRANSACTION_PROCESSING
                );
              },
            },
            {
              text: PAY_WITH_OTHER_METHODS,
              onClickHanlder: () => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_BANK
                );
              },
            },
          ]
        );
        // setErrorMessage(message);
        return setInvalidOTP(true);
      } else if (responseBody.status === TRANSACTION_STATUS.SUCCESS) {
        return onComplete(responseBody.status, responseBody);
      }
    } catch (error) {
      const errorData = (error && error.response && error.response.data) || {};
      setCheckingPayment(false);
      return setErrorMessage(errorData.responseMessage);
    }
  };
  const onComplete = async (status, data) => {
    const queryResponse = await TransactionService.queryTransactionStatus(
      params.id,
      context?.paymentInfo?.paymentData?.apiKey,
      context?.paymentInfo?.configData?.apiUrl
    );

    let responseData = (queryResponse && queryResponse.data) || {};
    let queryResponseBody = responseData.responseBody;

    let queryResponseTransactionStatus =
      queryResponseBody.paymentStatus || TRANSACTION_STATUS.EXPIRED;

    if (
      queryResponseTransactionStatus === TRANSACTION_STATUS.PAID ||
      queryResponseTransactionStatus === TRANSACTION_STATUS.PARTIALLY_PAID
    ) {
      context.setPaymentCompleteInfo(queryResponseBody);
      context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
    }
  };
  const startPolling = () => {
    try {
      setPolling(true);
      const id = setInterval(() => {
        queryTransationStatus();
      }, 3000);

      pollingIntervalID = id;
      setIntervalId(id);
    } catch (error) {
      stopPolling();
    }
  };

  const [asynSource, setSource] = useState(null);

  useEffect(() => {
    const source = Axios.CancelToken.source();

    setSource(source);

    return () => {
      if (asynSource) {
        asynSource.cancel();
      }
      if (intervalID) {
        console.log("cleared oooo");
        clearInterval(intervalID);
      }
    };
  }, []);

  const stopPolling = () => {
    setPolling(false);
    if (pollingIntervalID) {
      clearInterval(pollingIntervalID);
    }
  };

  const queryTransationStatus = () => {
    TransactionService.queryDirectDebitTransactionStatus(
      params.id,
      providerReference,
      context?.paymentInfo?.configData?.collectionChannel,
      context?.paymentInfo?.configData?.apiUrl,
      asynSource
    )
      .then(async (response) => {
        let responseData = (response && response.data) || {};
        if (
          !responseData ||
          !responseData.requestSuccessful ||
          !responseData.responseBody
        ) {
          return;
        }

        let responseBody = responseData.responseBody;
        let transactionStatus =
          responseBody.transactionStatus || TRANSACTION_STATUS.EXPIRED;

        if (
          transactionStatus === TRANSACTION_STATUS.SUCCESS ||
          transactionStatus === TRANSACTION_STATUS.PAID ||
          transactionStatus === TRANSACTION_STATUS.PARTIALLY_PAID
        ) {
          stopPolling();

          return onComplete(transactionStatus, responseBody);
        }
        if (transactionStatus === TRANSACTION_STATUS.FAILED) {
          stopPolling();

          return handleErrorLoadingAccount(responseBody);
        }
      })
      .catch((error) => {
        let errorData = (error && error.response && error.response.data) || {};
        // let transactionStatus = errorData && errorData.paymentStatus;

        console.log("error Data: ", errorData);
        handleErrorLoadingAccount(errorData);
      });
  };

  const handleErrorLoadingAccount = (data) => {
    let error =
      (data && data.responseMessage) || "Transaction could not be completed";
    setErrorMessage(error);
    context.changeTransactionStage(
      APP_STAGE.TRANSACTION_FAILED,
      error,
      "Transaction Failed",
      [
        {
          text: "Try again with Bank",
          onClickHanlder: () => {
            context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
          },
        },
        {
          text: PAY_WITH_OTHER_METHODS,
          onClickHanlder: () => {
            TransactionService.switchNextPaymentMethod(
              context,
              constants.PAY_WITH_BANK
            );
          },
        },
      ]
    );
  };
  return (
    <>
      {!providerReference ? (
        <Loader />
      ) : (
        <>
          {errorMessage && (
            <>
              <Fade top>
                <Banner type={ERROR_BANNER} text={errorMessage} />
              </Fade>
            </>
          )}
          <div className="d-flex justify-content-center mt-3">
            <MonnifyIcons type={OTP_ICON} />
          </div>
          <div className="py-2"></div>
          <div className="row">
            <div className="col-md-12 no-horizontal-padding">
              <h3 className="text-center sub-title text-black">Enter OTP</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 no-horizontal-padding">
              <h3 className="text-center sub-title text-sub-title">
                An OTP has been sent to your phone number. Enter it to complete
                this payment
              </h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 no-horizontal-padding">
              <div className="pan-wrapper">
                <label>OTP</label>
                <div className="pan-input-wrapper">
                  <TextInput
                    name="token"
                    onChange={handleOnChange}
                    placeholder="123456"
                    type="text"
                    value={token}
                    disabled={invalidOTP}
                  />
                </div>
              </div>
            </div>
          </div>
          {invalidOTP || (
            <div className="row mt-2">
              <div className="col-md-12 no-horizontal-padding">
                <Button
                  onClick={authorizePayment}
                  style={{
                    padding:
                      checkingPayment || isPolling ? "0px 8px" : "10px 8px",
                  }}
                >
                  {}
                  {isPolling ? (
                    <>
                      {"Checking for Payment..."}
                      <span>
                        <MonnifyIcons type={SPINNER_WHITE} />
                      </span>
                    </>
                  ) : checkingPayment ? (
                    <>
                      {"Validating..."}
                      <span>
                        <MonnifyIcons type={SPINNER_WHITE} />
                      </span>
                    </>
                  ) : (
                    <>{"Confirm"}</>
                  )}
                </Button>
              </div>
            </div>
          )}
          <div className="d-flex mt-4 justify-content-center w-100">
            <span className="cursor-pointer" onClick={resetStage}>
              <h2 onClick={resetStage} className="go-back-text">
                Go back
              </h2>
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default EnterOTP;
