import * as React from "react";
import "./index.scss";
import Loader from "../../../component/loader";
// import MonnifyPaymentData from "../../../services/data.service";
import { TRANSACTION_STATUS } from "../../../services/transaction-status.constant";
// import MonnifyConfig from "../../../services/config.service";
import SockJsClient from "react-stomp";
import { isChrome } from "react-device-detect";
import StoreContext from "../../../services/store/store-context";
// import NewWindow from 'react-new-window'
// import External3dsVerificationForm from './window-3d-verification'

class Secure3DVerification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingSecure3D: true,
      confirmingPaymentStatus: false,
      listeningForPayment: false,
      socketURL: null,
      socketTopics: [],
    };

    this.onFrameLoad = this.onFrameLoad.bind(this);

    this.MonnifyConfig = {};
  }

  componentDidMount() {
    this.MonnifyConfig = this.context?.paymentInfo || {};
    this.MonnifyPaymentData = this.context?.paymentInfo?.paymentData || {};
    this.stopWebSocket();
    let loadInNewWindow = this.shouldLoad3DSFormInNewWindow();
    this.form.submit();
    if (loadInNewWindow === true) {
      this.setState({ confirmingPaymentStatus: true });
    }
    this.listenForCompletion();
  }

  getFormMethod() {
    if (!this.context?.cardDetails?.secure3dData) {
      return "POST";
    }

    return this.context?.cardDetails?.secure3dData?.method;
  }

  getFrameClass() {
    return this.state.loadingSecure3D || this.state.confirmingPaymentStatus
      ? "hide"
      : "show";
  }

  onSocketMessageReceived = (transactionResponse) => {
    let responseData = transactionResponse || {};
    if (!responseData.paymentStatus) {
      this.onComplete(TRANSACTION_STATUS.FAILED, {});
      return;
    }

    let transactionStatus = responseData.paymentStatus;
    this.onComplete(transactionStatus, responseData);
  };

  stopWebSocket = () => {
    this.setState({
      listeningForPayment: false,
      socketTopics: [],
      socketURL: null,
    });
    this.socketClientRef && this.socketClientRef.disconnect();
  };

  listenForCompletion = () => {
    if (this.state.listeningForPayment) {
      return;
    }
    let socketTopics = [];
    let url = `${this.MonnifyConfig.configData?.webSocketUrl}`;
    let topic = `/transaction/${this.MonnifyPaymentData.transactionReference}`;
    socketTopics.push(topic);
    this.setState({
      loadingSecure3D: false,
      listeningForPayment: true,
      socketTopics: socketTopics,
      socketURL: url,
    });
  };

  onComplete(status, data) {
    this.props.onComplete && this.props.onComplete(status, data);
  }

  onFrameLoad() {
    console.log("ifframe loaded: ", this.state.confirmingPaymentStatus);
    this.setState({ loadingSecure3D: false });
    this.listenForCompletion();
  }

  getWaitingText() {
    if (this.state.confirmingPaymentStatus) {
      return "Please wait while we confirm your payment status...";
    }

    if (this.state.loadingSecure3D) {
      return "Please wait while we take you to authorize your transaction...";
    }

    return "Waiting for Secure-3D Authorization...";
  }

  shouldLoad3DSFormInNewWindow = () => {
    let loadInNewWindow = true;

    if (isChrome === true) {
      loadInNewWindow = false;
    }

    return loadInNewWindow;
  };

  render() {
    let data = this.context?.cardDetails?.secure3dData || {};
    let url = data.url || data.redirectUrl;

    let loadInNewWindow = this.shouldLoad3DSFormInNewWindow();

    return (
      <div>
        {/* {loadInNewWindow === true &&
                    <div>
                        <NewWindow center="parent">
                            <External3dsVerificationForm url={url} method={this.getFormMethod()}
                                waitingText={this.getWaitingText()} />
                        </NewWindow>
                        <Loader text={this.getWaitingText()} />
                    </div>

                } */}

        {loadInNewWindow === true && (
          <div className="secure-3d-verification">
            <form
              action={url}
              method={this.getFormMethod()}
              ref={(f) => {
                this.form = f;
              }}
              target="_blank"
            ></form>
            <Loader text={this.getWaitingText()} />
          </div>
        )}

        {loadInNewWindow === false && (
          <div className="secure-3d-verification">
            <form
              action={url}
              method={this.getFormMethod()}
              ref={(f) => {
                this.form = f;
              }}
              target="secure3dFrame"
            ></form>
            <Loader text={this.getWaitingText()} />
          </div>
        )}

        <iframe
          title="Monnify Secure-3D Authorization"
          sandbox="allow-forms allow-scripts allow-same-origin allow-popups allow-pointer-lock"
          className={this.getFrameClass()}
          id="secure3dFrame"
          name="secure3dFrame"
          onLoad={this.onFrameLoad}
        >
          {" "}
        </iframe>

        {this.state.listeningForPayment && (
          <SockJsClient
            url={this.state.socketURL}
            topics={this.state.socketTopics}
            onMessage={this.onSocketMessageReceived}
            ref={(client) => {
              this.socketClientRef = client;
            }}
          />
        )}
      </div>
    );
  }
}

Secure3DVerification.contextType = StoreContext;

export default Secure3DVerification;
