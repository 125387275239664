import React, { useCallback, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import MonnifyIcons from "../../icon";
import StoreContext from "../../services/store/store-context";
import { RESPONSE_CODE } from "../../services/transaction-status.constant";
import TransactionService from "../../services/transaction.service";
import { STAGES as APP_STAGE } from "../../App";
import constants from "../../constants";
import { PAY_WITH_OTHER_METHODS } from "../../services/constants";

const Loading = ({ onLoaded }) => {
  const params = useParams();
  const context = useContext(StoreContext);

  const initializeTransaction = useCallback(async () => {
    try {
      const response = await TransactionService.initializeBankTransaction(
        {
          collectionChannel:
            context?.paymentInfo?.configData?.collectionChannel,
          apiKey: context?.paymentInfo?.paymentData?.apiKey,
          transactionReference: params.id,
        },
        context?.paymentInfo?.configData?.apiUrl
      );
      const responseBody = response.data.responseBody || {};
      context.updatePaymentData({
        paymentData: {
          ...context?.paymentInfo?.paymentData,
          amount: responseBody.amount,
          totalAmountPayable: responseBody.totalAmountPayable,
        },
      });
      onLoaded();
    } catch (error) {
      const errorData = (error && error.response && error.response.data) || {};
      handleErrorLoadingAccount(errorData);
    }
  }, [
    params.id,
    context?.paymentInfo?.configData?.apiUrl,
    context?.paymentInfo?.paymentData?.apiKey,
    context?.paymentInfo?.configData?.collectionChannel,
  ]);
  useEffect(() => {
    initializeTransaction();
  }, []);

  const handleErrorLoadingAccount = useCallback((data) => {
    const error =
      (data && data.responseMessage) ||
      "Unable to fetch payment method details. Please try again or use another payment method";
    const responseCode = error.responseCode;
    console.log("error is : ", error);

    if (responseCode === RESPONSE_CODE.TRANSACTION_COMPLETED) {
      context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
      return;
    }
    context.changeTransactionStage(
      APP_STAGE.TRANSACTION_FAILED,
      error,
      "Transaction Failed",
      [
        {
          text: "Try again with Bank",
          onClickHanlder: () => {
            context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
          },
        },
        {
          text: PAY_WITH_OTHER_METHODS,
          onClickHanlder: () => {
            TransactionService.switchNextPaymentMethod(
              context,
              constants.PAY_WITH_BANK
            );
          },
        },
      ]
    );
  }, []);

  return (
    <div className="loading-wrapper mt-5">
      <div className="loading-container">
        <MonnifyIcons type="ROLLER_ICON" style={{}} />
      </div>
    </div>
  );
};

export default Loading;
