import { useContext, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import constants from "../../constants";
import { PAYMENT_METHODS } from "../../services/constants";
import StoreContext from "../../services/store/store-context";
import TransactionService from "../../services/transaction.service";
import "./index.scss";

const Header = () => {
  const context = useContext(StoreContext);

  const [totalAmountPayable, setTotalAmountPayable] = useState(0);
  const params = useParams();

  const intializePaymentMethod = useCallback(async () => {
    setTotalAmountPayable(context?.paymentInfo?.paymentData?.amount);
    if (
      typeof context?.paymentInfo?.configData?.apiUrl === "undefined" ||
      !context.activeLink
    )
      return;
    const response = await TransactionService.getTransactionFee(
      context?.paymentInfo?.configData?.apiUrl,
      {
        transactionReference: params.id,
        contractCode: context?.paymentInfo?.paymentData?.contractCode,
        collectionChannel: context?.paymentInfo?.configData?.collectionChannel,
        paymentMethod: getPaymentMethod(),
        amount: context?.paymentInfo?.paymentData?.amount,
      }
    );

    const responseBody = response.data.responseBody;
    const { payableAmount } = responseBody;
    setTotalAmountPayable(payableAmount);
  }, [context.activeLink, context?.paymentInfo?.configData?.apiUrl]);

  const getPaymentMethod = () => {
    switch (context.activeLink) {
      case constants.PAY_WITH_ACCOUNT_TRANSFER:
        return PAYMENT_METHODS.ACCOUNT_TRANSFER;
      case constants.PAY_WITH_BANK:
        return PAYMENT_METHODS.DIRECT_DEBIT;
      case constants.PAY_WITH_CARD:
        return PAYMENT_METHODS.CARD;
      case constants.PAY_WITH_USSD:
        return PAYMENT_METHODS.USSD;
      case constants.PAY_WITH_PHONE_NO:
        return PAYMENT_METHODS.PHONE_NUMBER;
      case constants.PAY_WITH_CASH:
        return PAYMENT_METHODS.CASH;
      case constants.PAY_WITH_NQR:
        return PAYMENT_METHODS.NQR;
      default:
        return "none";
    }
  };

  useEffect(() => {
    intializePaymentMethod();
  }, [intializePaymentMethod]);

  useEffect(() => {
    setTotalAmountPayable(
      context?.paymentInfo?.paymentData?.totalAmountPayable
    );
  }, [context?.paymentInfo?.paymentData?.totalAmountPayable]);

  return (
    <div className="header-wrapper">
      <div className="customer-details">
        <div className="d-flex align-items-center ">
          {context?.paymentInfo?.paymentData?.merchant?.logo ? (
            <img
              alt="merchant"
              src={
                context?.paymentInfo?.paymentData?.merchant?.logo.startsWith(
                  "data:"
                )
                  ? context?.paymentInfo?.paymentData?.merchant?.logo
                  : `data:image/png;base64,${context?.paymentInfo?.paymentData?.merchant?.logo}`
              }
              className="merchant-logo"
            />
          ) : null}
          <h5 className="merchant-name header-custom-tooltip">
            {context?.paymentInfo?.paymentData?.merchant?.name
              ? `${context?.paymentInfo?.paymentData?.merchant?.name.substring(
                  0,
                  9
                )}...`
              : ""}
            <span className="tooltiptext">
              {context?.paymentInfo?.paymentData?.merchant?.name}
            </span>
          </h5>
        </div>
        <div>
          <h5 className="customer-name header-custom-tooltip">
            {context?.paymentInfo?.paymentData?.customer?.email?.substring(
              0,
              25
            ) || ""}
            <span className="tooltiptext">
              {context?.paymentInfo?.paymentData?.customer?.email}
            </span>
          </h5>
        </div>
      </div>
      <div className="payment-amount-info-wrapper">
        <h5 className="amount-to-pay">
          {context.isPaid ? "Amount Paid" : "Amount to pay"}
        </h5>
        <h4 className="amount">
          {TransactionService.formatAsMoney(totalAmountPayable)}
        </h4>
        <hr className="line-rule" />
        <div className="d-flex align-items-center">
          <div className="d-flex amount-desc-container">
            <h5>Amount</h5>
            <h5 className="amount-bold">
              {TransactionService.formatAsMoney(
                context?.paymentInfo?.paymentData?.amount || 0
              )}
            </h5>
          </div>
          <div className="d-flex amount-desc-container">
            <h5>Fee</h5>
            <h5 className="amount-bold">
              {TransactionService.formatAsMoney(
                totalAmountPayable -
                  context?.paymentInfo?.paymentData?.amount || 0
              )}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
