import { useState } from "react";
import MonnifyIcons from "../../icon";
import { COPY_ICON } from "../../icon/icon";
import Fade from "react-reveal/Fade";
import "./index.scss";

const ClipBoard = ({ text, defaultComponent }) => {
  const [isCopied, setCopied] = useState(false);
  const toggleCopy = async () => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
    } else {
      await navigator.clipboard.writeText(text);
    }
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };
  const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
    } catch (err) {}

    document.body.removeChild(textArea);
  };
  return (
    <div onClick={toggleCopy} className="clipborad-container">
      <>
        {defaultComponent || (
          <span>
            <MonnifyIcons type={COPY_ICON} />
          </span>
        )}
        {
          <div
            className="copy-action-wrapper"
            style={{ display: isCopied ? "block" : "none" }}
          >
            <Fade top>
              <div className="d-flex justify-content-center">
                <div className="copy-notify-banner">
                  <span>Copied!</span>
                </div>
              </div>
            </Fade>
          </div>
        }
      </>
    </div>
  );
};

export default ClipBoard;
