import styled from "styled-components";
import MonnifyIcons from "../../icon";
import { ARROW_FORWARD_ICON } from "../../icon/icon";

const Li = styled.li`
  display: flex;
  align-items: center;
  position: relative;
`;

export const ListItem = ({ onClick, className, name, iconName }) => {
  return (
    <Li className={className} onClick={onClick}>
      <span className="icon-img">
        <MonnifyIcons type={iconName} />

        <span className="sl-1 ">{name}</span>
      </span>
      <span className="icon-forward">
        <MonnifyIcons type={ARROW_FORWARD_ICON} />
      </span>

      <div className="top-curvy-border">
        <div className="inner"></div>
      </div>
      <div className="bottom-curvy-border">
        <div className="inner"></div>
      </div>
    </Li>
  );
};
