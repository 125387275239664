import React, { useContext, useEffect, useState } from "react";
import MonnifyIcons from "../../icon";
import { OTP_IMAGE } from "../../icon/icon";
import StoreContext from "../../services/store/store-context";

import { Button, TextInput } from "../../styled-components/Input";
import "./index.scss";

const OTP = ({ onNextPress, goBack }) => {
  const context = useContext(StoreContext);
  const [otp, setOtp] = useState("");
  // const params = useParams()

  const handleOnChange = (e) => {
    const {
      target: {  value },
    } = e;

    setOtp(value);
    
  };
  const accpetNumbersOnly = (e) => {
    const ARROW_KEYS = [37, 38, 39, 40];
    if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      e.keyCode === 8 ||
      ARROW_KEYS.includes(e.keyCode)
    ) {
      // 0-9 only
      return;
    }
    e.preventDefault();
  };

  useEffect(() => {
    const pin = otp;
    if (pin.trim().length < 6) return false;

    // do something interesting
  }, [otp, context, onNextPress]);

  const handleOnNextPress = () => {
    context.updateCardDetails({
      token: otp,
    });
    onNextPress();
  };

  return (
    <div className="vertical-align">
      <h2 className="text-center">
        <MonnifyIcons type={OTP_IMAGE} />
      </h2>
      <div className="row">
        

        <h3 className="text-grey text-center  text-black">
          Kindly enter OTP sent to your phone to complete this payment.
        </h3>
        <div className="py-1"></div>
        <div className="row no-horizontal-padding">
          <div className="col-md-12">
            <div className="pan-wrapper">
              <label>OTP</label>
              <div className="pan-input-wrapper">
                <TextInput
                  onKeyDown={accpetNumbersOnly}
                  value={otp}
                  name="otp"
                  onChange={handleOnChange}
                  type="text"
                  placeholder="123456"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 mb-3">
          <div className="col-md-12 no-horizontal-padding">
            <Button onClick={handleOnNextPress}>Submit</Button>
          </div>
        </div>
        <h2 onClick={goBack} className="go-back-text">
          Go back
        </h2>
      </div>
    </div>
  );
};

export default OTP;
