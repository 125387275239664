const constants = {
  PAY_WITH_CARD: "Pay with Card",
  PAY_WITH_ACCOUNT_TRANSFER: "Pay with Transfer",
  PAY_WITH_USSD: "Pay with USSD",
  PAY_WITH_NQR: "Pay with NQR",
  CARD: "CARD",
  TRANSFER: "TRANSFER",
  USSD: "USSD",
  NQR: "NQR",
  BANK: "BANK",
  PAY_WITH_CASH: "Pay with Cash",
  PAY_WITH_BANK: "Pay with Bank",
  PAY_WITH_PHONE_NO: "Pay with Phone No.",
  TRANSACTION_EXPIRED: "Transaction has expired.",
};

export default constants;
