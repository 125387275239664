import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import "./index.scss";
import MonnifyIcons from "../../icon";
import { ERROR_INFO, SPINNER_WHITE } from "../../icon/icon";
import { Button } from "../../styled-components/Input";
import { useParams } from "react-router-dom";
import Select from "../../component/select";
import StoreContext from "../../services/store/store-context";
import TransactionService from "../../services/transaction.service";
import SockJsClient from "react-stomp";
import Loader from "../../component/loader";
import { STAGES as APP_STAGE } from "../../App";
import {
  isTransactionPending,
  isTransactionSuccessful,
  RESPONSE_CODE,
  TRANSACTION_STATUS,
} from "../../services/transaction-status.constant";
import { ERROR_BANNER, PAY_WITH_OTHER_METHODS } from "../../services/constants";
import Fade from "react-reveal/Fade";
import ClipBoard from "../../component/ClipBoard";
import ProgressBar2 from "./ProgressBar2";
import classNames from "classnames";
import constants from "../../constants";

const BankTranfer = (props) => {
  const context = useContext(StoreContext);
  const params = useParams();
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bank, setBank] = useState("");
  const [validatyTime, setValidityTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [socketTopics, setSocketTopics] = useState("");
  const [socketUrl, setSocketUrl] = useState("");
  const [listenForPayment, setListForPayment] = useState(false);
  let socketClientRef = useRef();
  const [messageReceived, setMessageReceived] = useState(null);
  const [, setPaymentStatusIntervalId] = useState(null);
  const [showError, setShowError] = useState(false);
  const [banks, setBanks] = useState([]);

  const PAYMENT_STATUS_CONFIG = useMemo(
    () => ({
      poll: 15000,
      enableCheckPaymentButton: 180000,
      clearTimeOutForButton: 4000,
    }),
    []
  );

  const getBanks = useCallback(async () => {
    try {
      const response = await TransactionService.getBanks(
        context?.paymentInfo?.configData?.apiUrl
      );
      const responseData = response.data || {};
      const data = responseData.responseBody || [];

      setBanks(data);
    } catch (error) {
      // do something with the error
    }
  }, [context?.paymentInfo?.configData?.apiUrl]);

  const handleErrorLoadingAccount = (data) => {
    setLoadingFailed(true);

    let error =
      (data && data.responseMessage) ||
      "Unable to fetch payment method details. Please try again or use another payment method";

    context.changeTransactionStage(
      APP_STAGE.TRANSACTION_FAILED,
      error,
      "Transaction Failed",
      [
        {
          text: "Try again with Transfer",
          onClickHanlder: () => {
            context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
          },
          desc: "New account number will be generated",
        },
        {
          text: PAY_WITH_OTHER_METHODS,
          onClickHanlder: () => {
            TransactionService.switchNextPaymentMethod(
              context,
              constants.PAY_WITH_ACCOUNT_TRANSFER
            );
          },
        },
      ]
    );
  };

  const listenForPaymentOnSocket = useCallback(() => {
    let socketTopics = [];
    let url = `${context?.paymentInfo?.configData?.webSocketUrl}`;
    let topic = `/transaction/${params.id}`;
    socketTopics.push(topic);

    setSocketTopics(socketTopics);
    setSocketUrl(url);
    setListForPayment(true);
  }, [context?.paymentInfo?.configData?.webSocketUrl, params.id]);

  const initializeTransaction = useCallback(async () => {
    try {
      if (!context || !context.paymentInfo) return;
      setLoading(true);
      const response = await TransactionService.initializeBankTransfer(
        {
          collectionChannel:
            context?.paymentInfo?.configData?.collectionChannel,
          apiKey: context?.paymentInfo?.paymentData?.apiKey,
          transactionReference: params.id,
        },
        context?.paymentInfo?.configData?.apiUrl
      );
      let responseData = (response && response.data) || {};
      setLoading(false);
      if (
        !responseData ||
        !responseData.requestSuccessful ||
        !responseData.responseBody
      ) {
        // display error message
        handleErrorLoadingAccount(responseData);
        return;
      }
      let responseBody = responseData.responseBody;

      setLoadingFailed(false);

      setAccountNumber(responseBody.accountNumber);
      setAccountName(responseBody.accountName);
      setBank(responseBody.bankName);
      const validity = Math.round(
        (responseBody.accountDurationSeconds / 60).toFixed(2)
      );
      setValidityTime(validity);

      context.updatePaymentData({
        paymentData: {
          ...context?.paymentInfo?.paymentData,
          amount: responseBody.amount,
          totalPayable: responseBody.totalPayable,
        },
      });

      listenForPaymentOnSocket();
    } catch (error) {
      setLoading(false);
      let errorData = (error && error.response && error.response.data) || {};
      let responseCode = errorData.responseCode;

      if (responseCode === RESPONSE_CODE.TRANSACTION_COMPLETED) {
        return context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
      }
      // do something with errror message
      handleErrorLoadingAccount(errorData);
    }
  }, [
    context?.paymentInfo?.configData?.collectionChannel,
    context?.paymentInfo?.paymentData?.apiKey,
    context?.paymentInfo?.configData?.apiUrl,
    params.id,
    listenForPaymentOnSocket,
  ]);

  useEffect(() => {
    initializeTransaction();
  }, [initializeTransaction]);
  const [checkingPayment, setCheckingPayment] = useState(false);

  const handleCheckPayment = async () => {
    try {
      if (checkingPayment) return;
      setCheckingPayment(true);
      const response = await queryTransactionStatus();

      setCheckingPayment(false);
      if (isTransactionPending(response.transactionStatus)) {
        setShowError(true);

        setTimeout(() => setShowError(false), 5000);
      } else if (isTransactionSuccessful(response.transactionStatus)) {
        onComplete(response.transactionStatus, response.responseBody);
      }
    } catch (error) {
      console.log("error> ", error);
      setCheckingPayment(false);
      let errorData = (error && error.response && error.response.data) || {};
      handleErrorLoadingAccount(errorData);
    }
  };

  const startPollingPaymentStatus = (client) => {
    const id = setInterval(() => {}, PAYMENT_STATUS_CONFIG.poll);
    setPaymentStatusIntervalId(id);
  };

  const queryTransactionStatus = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await TransactionService.queryTransactionStatus(
          context?.paymentInfo?.paymentData?.transactionReference,
          context?.paymentInfo?.paymentData?.apiKey,
          context?.paymentInfo?.configData?.apiUrl
        );
        let responseData = (response && response.data) || {};
        let responseBody = responseData.responseBody;
        let transactionStatus =
          responseBody.paymentStatus || TRANSACTION_STATUS.EXPIRED;
        resolve({ responseBody, transactionStatus });
      } catch (error) {
        console.log("error> ", error);
        let errorData = (error && error.response && error.response.data) || {};
        reject(errorData);
      }
    });
  };

  const onSocketMessageReceived = (message) => {
    if (!message) {
      return;
    }

    if (isTransactionSuccessful(message.paymentStatus)) {
      // todo: record received payment
      setMessageReceived(true);
      return onComplete(message.paymentStatus, message);
    }
  };

  const getPaymentActionText = () => {
    return "I’ve transferred the money";
  };

  const onComplete = (status, data) => {
    context.setPaymentCompleteInfo(data);
    context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
  };

  const onTimerComplete = useCallback(() => {
    context.changeTransactionStage(
      APP_STAGE.TRANSACTION_FAILED,
      `Your payment of ${TransactionService.formatAsMoney(
        context?.paymentInfo?.paymentData?.amount || 0
      )} was not successful`,
      "Payment details expired",
      [
        {
          text: "Try again with Transfer",
          onClickHanlder: () => {
            context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
          },
          desc: "New account number will be generated",
        },
        {
          text: PAY_WITH_OTHER_METHODS,
          onClickHanlder: () => {
            TransactionService.switchNextPaymentMethod(
              context,
              constants.PAY_WITH_ACCOUNT_TRANSFER
            );
          },
        },
      ]
    );
  }, []);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    () => {
      return TransactionService.source.cancel("canclled");
    };
  }, []);

  return (
    <>
      {loading && !loadingFailed && <Loader />}

      {!loadingFailed && !loading && (
        <Fade>
          <div className={classNames("container-fluid")}>
            <div
              className={classNames(
                "pay-with-card-content-wrapper",
                props.className
              )}
            >
              {showError && (
                <Fade top>
                  <Banner type={ERROR_BANNER} />
                </Fade>
              )}
              <div className="row">
                <div className="col-md-12">
                  <h3 className="text-center sub-title text-black lh-2 font-inter f-w-500">
                    Transfer to account details below
                  </h3>
                </div>
              </div>

              <div className="tranfer-blue-light-bg">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="h-sub-title text-center ussd-bank-name ls-1 mb-0">
                      {bank}
                    </h2>
                    <div className="d-flex flex-column align-items-center mb-1">
                      <div className="d-flex justify-content-center align-item-end">
                        <h2 className="h-title mb-0 text-center font-inter text-primary-blue ls-1 f-16">
                          {accountNumber}
                        </h2>
                        <ClipBoard text={accountNumber} />
                      </div>
                      <div className="info-banner info-banner-small">
                        <h6 className="text-center sub-title lh-2 font-inter f-w-500">
                          Do not save this account no.!
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-md-12">
                    <h4 className="h-sub-title  text-color-grey text-center">
                      Account Name
                    </h4>

                    <h2 className="text-center f-s-12 ls-0 bank-name font-bold font-inter">
                      {accountName}
                    </h2>
                  </div>
                </div>
                <div className="py-1"></div>
                <ProgressBar2
                  countDown={validatyTime}
                  onTimerComplete={onTimerComplete}
                />
              </div>
              <div className="m-y-12"></div>
              <PaymentOptonTrail
                banks={banks}
                amount={context?.paymentInfo?.paymentData?.amount}
                accountNumber={accountNumber}
              />

              <Button
                onClick={handleCheckPayment}
                style={{ padding: checkingPayment ? "0px 8px" : "10px 8px" }}
              >
                {getPaymentActionText()}{" "}
                {checkingPayment && (
                  <span>
                    <MonnifyIcons type={SPINNER_WHITE} />
                  </span>
                )}
              </Button>
            </div>
          </div>
        </Fade>
      )}
      {listenForPayment && (
        <SockJsClient
          url={socketUrl}
          topics={socketTopics}
          onMessage={onSocketMessageReceived}
          ref={(client) => {
            socketClientRef = client;
          }}
          autoReconnect={false}
          onConnect={() => {}}
          onDisconnect={() => {
            if (!messageReceived) startPollingPaymentStatus(socketClientRef);
          }}
        />
      )}
    </>
  );
};

export const Banner = ({ type, text }) => {
  const Error = () => {
    return (
      <div className="monnify-banner">
        <div className="monnify-error-banner">
          <span>
            <MonnifyIcons
              style={{ display: "flex", alignItem: "center" }}
              type={ERROR_INFO}
            />
          </span>
          <article>
            {text ||
              "Transfer not received yet. Please wait while we confirm payment."}
          </article>
        </div>
      </div>
    );
  };

  const getBannerType = () => {
    switch (type) {
      case ERROR_BANNER:
        return <Error />;
      default:
        return null;
    }
  };
  return getBannerType();
};

const PaymentOptonTrail = ({ banks, amount, accountNumber }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ussdTemplate, setTemplate] = useState(null);
  const [bankCode, setBankCode] = useState("");
  const handleOnBankChange = (e) => {
    const bankCode = e.target.value;
    setBankCode(bankCode);
    const bank = banks.find((item) => item.code === bankCode) || {};
    const template = bank?.ussdTemplate
      ?.replace("AccountNumber", accountNumber)
      .replace("Amount", amount);
    setTemplate(template);
  };

  const getFontSize = () => {
    return ussdTemplate.length > 22 ? "font-small" : "";
  };

  const toggleState = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    if (!isOpen) {
      setTemplate(null);
      setBankCode("");
    }
  }, [isOpen]);

  return (
    <div className="animate__animated animate__fadeIn">
      <h2
        onClick={toggleState}
        className="h-title f-w-500 mb-0 text-primary-blue f-s-12 fnt-medium cursor-pointer text-center"
      >
        {isOpen ? "Close USSD code" : "Get your bank's USSD code"}
      </h2>
      <hr className="m-y-12 border-grey" />
      {isOpen ? (
        <Fade>
          <>
            <Select
              onSelect={handleOnBankChange}
              placeholder={"Select Bank"}
              options={banks.map((item) => ({
                label: item.name,
                value: item.code,
              }))}
              value={bankCode}
            />
            {banks.length > 0 && ussdTemplate && (
              <Fade top>
                <div className="py-2"></div>
                <h1 className="h-sub-title text-center">Dial the code </h1>
                <div className="ussd-code-container ls-1">
                  <h3 className={getFontSize()}>{ussdTemplate}</h3>
                </div>
                <div className="py-2"></div>

                <ClipBoard
                  text={ussdTemplate}
                  defaultComponent={
                    <h1 className="h-sub-title text-center cursor-pointer text-grey">
                      Click to Copy
                    </h1>
                  }
                />
                <div className="py-3"></div>
              </Fade>
            )}
          </>
        </Fade>
      ) : null}
    </div>
  );
};

export default BankTranfer;
