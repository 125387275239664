import classNames from "classnames";
import { useState } from "react";
import MonnifyIcons from "../../icon";
import { PAY_ATTITUDE_ICON } from "../../icon/icon";
import ConfirmTransaction from "./ConfirmTransaction";
import EnterPhoneNUmber from "./EnterPhoneNumber";
import "./index.scss";

const STAGE = {
  ENTER_PHONE_NUMBER: "ENTER_PHONE_NUMBER",
  CONFIRMING_TRANSACTION: "CONFIRMING_TRANSACTION",
};

const PayWithPhone = (props) => {
  const [stage, setStage] = useState(STAGE.ENTER_PHONE_NUMBER);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState(null);
  const onPhoneNumberComplete = (data) => {
    if (data.trim().startsWith("+234")) {
      setPhoneNumber(`0${data.substring(4, data.length)}`);
    } else {
      setPhoneNumber(data);
    }

    setMessage(null);
    setStage(STAGE.CONFIRMING_TRANSACTION);
  };

  const goToPrevious = (errorMessage) => {
    setMessage(errorMessage);
    setStage(STAGE.ENTER_PHONE_NUMBER);
  };
  const clearMessage = () => {
    setMessage(null);
  };
  const getTransactionStage = () => {
    switch (stage) {
      case STAGE.ENTER_PHONE_NUMBER:
        return (
          <EnterPhoneNUmber
            message={message}
            onPhoneNumberComplete={onPhoneNumberComplete}
            phoneNumber={phoneNumber}
            clearMessage={clearMessage}
          />
        );
      case STAGE.CONFIRMING_TRANSACTION:
        return (
          <ConfirmTransaction
            phoneNumber={phoneNumber}
            goToPrevious={goToPrevious}
          />
        );
      default:
        return (
          <EnterPhoneNUmber
            onPhoneNumberComplete={onPhoneNumberComplete}
            message={message}
            phoneNumber={phoneNumber}
            clearMessage={clearMessage}
          />
        );
    }
  };
  return (
    <div
      className={classNames(
        "container-fluid",
        "pay-with-card-content-wrapper",
        "flex-direction-column",
        "h-100",
        props.className
      )}
    >
      <div className="flex-1">{getTransactionStage()}</div>
      <div className="mt-5 d-flex justify-content-center">
        <MonnifyIcons type={PAY_ATTITUDE_ICON} />
      </div>
    </div>
  );
};

export default PayWithPhone;
