import React, { useCallback, useEffect, useState } from "react";

const ProgressBar2 = ({ countDown, onTimerComplete }) => {
  const [intervalId, setIntervalId] = useState(null);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [minutesBal, setMinutesBal] = useState(0);
  const [minutesBalLocal, setMinutesBalLocal] = useState(0);
  const [secondBal, setSecondsBal] = useState(0);
  const getTotalTime = useCallback((minutes = 4) => {
    setTotalMinutes(minutes * 60);
  }, []);
  const minutesRemaining = useCallback(() => {
    const bal = Math.trunc((totalMinutes - minutesBalLocal) / 60);

    setMinutesBal(bal);
  }, [totalMinutes, minutesBalLocal]);
  const secondsRemaining = useCallback(() => {
    const bal = Math.floor((totalMinutes - minutesBalLocal) / 60);

    const seconds = totalMinutes - bal * 60 - minutesBalLocal;

    setSecondsBal(seconds);
  }, [totalMinutes, minutesBalLocal]);

  useEffect(() => {
    getTotalTime(countDown);
  }, [getTotalTime, countDown]);

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);
  useEffect(() => {
    minutesRemaining();
    secondsRemaining();
  }, [minutesBalLocal, minutesRemaining, secondsRemaining]);
  useEffect(() => {
    if (!intervalId && minutesBalLocal < totalMinutes) {
      const id = setInterval(() => {
        setMinutesBalLocal((level) => level + 1);
        setSecondsBal((level) => level - 1);
      }, 1000);

      setIntervalId(id);
    }
  }, [intervalId, totalMinutes, minutesBalLocal]);
  useEffect(() => {
    if (minutesBalLocal >= totalMinutes) {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
        onTimerComplete();
      }
    }
  }, [minutesBalLocal, intervalId, totalMinutes]);

  return (
    <div className="row">
        <div className="progressbar-container2">
          <div className="overlay"></div>
          <div
            style={{ width: `${(minutesBalLocal / totalMinutes) * 100}%` }}
            className="progress"
          ></div>
        </div>
        <div className="py-1"></div>
        <div className="d-flex progress-amount-desc-container">
          {minutesBalLocal >= totalMinutes ? (
            <span className="counter-text">Expired!</span>
          ) : (
            <>
              <h5 className="counter-text">This one-time account expires in</h5>
              <h5 className="amount-bold counter-text">
                {minutesBal} {minutesBal > 1 ? "mins" : "min"} {secondBal}
                {secondBal > 1 ? " secs" : " sec"}
              </h5>
            </>
          )}
        </div>
    </div>
  );
};

//(minutesBalLocal / totalMinutes) * 100

export default ProgressBar2;
